import { call, put } from 'redux-saga/effects'

import NavActions from '../Redux/NavRedux'

export function* getNavLinks(api) {
    const response = yield call(api.menus.getLinks)
    if (response) {
        yield put(NavActions.getNavLinksSuccess(response))
    } else {
        yield put(NavActions.getNavLinksFailure(response))
    }
}
