import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getDepartmentsRequest: null,
    getDepartmentsSuccess: ['payload'],
    getDepartmentsFailure: ['error'],

    getDepartmentRequest: ['id'],
    getDepartmentSuccess: ['payload'],
    getDepartmentFailure: ['error'],

    getDepartmentAgencies: ['payload']
})

export const DepartmentTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    departments: {
        fetching: false,
        data: null,
        error: null
    },
    department: {
        fetching: false,
        data: null,
        error: null
    }
})

/* ------------- Selectors ------------- */

export const DepartmentSelectors = {
    getDepartments: (state) => state.department.departments.data,
    getDepartment: (state) => state.department.department.data
}

/* ------------- Reducers ------------- */

const getDepartmentsRequest = (state = INITIAL_STATE) =>
    state.merge({ departments: { ...state.departments, fetching: true } })

const getDepartmentsSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ departments: { ...state.departments, data: payload } })

const getDepartmentsFailure = (state = INITIAL_STATE, { error }) =>
    state.merge({ departments: { ...state.departments, error } })

const getDepartmentRequest = (state = INITIAL_STATE) =>
    state.merge({ department: { ...state.department, fetching: true } })

const getDepartmentSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ department: { ...state.department, data: payload } })

const getDepartmentFailure = (state = INITIAL_STATE, { error }) =>
    state.merge({ department: { ...state.department, error } })

const getDepartmentAgencies = (state) => state

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_DEPARTMENTS_REQUEST]: getDepartmentsRequest,
    [Types.GET_DEPARTMENTS_SUCCESS]: getDepartmentsSuccess,
    [Types.GET_DEPARTMENTS_FAILURE]: getDepartmentsFailure,

    [Types.GET_DEPARTMENT_REQUEST]: getDepartmentRequest,
    [Types.GET_DEPARTMENT_SUCCESS]: getDepartmentSuccess,
    [Types.GET_DEPARTMENT_FAILURE]: getDepartmentFailure,

    [Types.GET_DEPARTMENT_AGENCIES]: getDepartmentAgencies
})
