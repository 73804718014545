import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Loader from '../../../../Components/Loader'
import { UISelectors } from '../../../../Redux/UIRedux'

class AppLayout extends Component {
    renderLoader = () => {
        return <Loader />
    }

    render() {
        const { loading, children } = this.props
        return <>{loading ? this.renderLoader() : children}</>
    }
}

AppLayout.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node
}

const mapStateToProps = (state) => ({
    loading: UISelectors.loading(state)
})

export default connect(mapStateToProps)(AppLayout)
