import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    showLoader: null,
    hideLoader: null,
    showPender: null,
    hidePender: null
})

export const UITypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    loading: false,
    pending: false
})

/* ------------- Selectors ------------- */

export const UISelectors = {
    loading: (state) => state.ui.loading,
    pending: (state) => state.ui.pending
}

/* ------------- Reducers ------------- */

// shows the loader
const showLoader = (state) =>
    state.merge({
        loading: true
    })

// hides the loader
const hideLoader = (state) =>
    state.merge({
        loading: false
    })

// shows the pender
const showPender = (state) =>
    state.merge({
        pending: true
    })

// hides the pender
const hidePender = (state) =>
    state.merge({
        pending: false
    })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SHOW_LOADER]: showLoader,
    [Types.HIDE_LOADER]: hideLoader,
    [Types.SHOW_PENDER]: showPender,
    [Types.HIDE_PENDER]: hidePender
})
