/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get' || method === 'delete' || method === 'head' || method === 'option') {
    return axios[method](queryUrl, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    annuaireBuralistesApi API documentation
 ==========================================================*/
/**
 * getCity
 * request: getCityUsingGET
 * url: getCityUsingGETURL
 * method: getCityUsingGET_TYPE
 * raw_url: getCityUsingGET_RAW_URL
 * @param country - country
 * @param name - name
 */
export const getCityUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {
    headers: {}
  }
  let path = '/cities/{name}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  path = path.replace('{name}', `${parameters['name']}`)
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCityUsingGET_RAW_URL = function() {
  return '/cities/{name}'
}
export const getCityUsingGET_TYPE = function() {
  return 'get'
}
export const getCityUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/cities/{name}'
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  path = path.replace('{name}', `${parameters['name']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getClusters
 * request: getClustersUsingGET
 * url: getClustersUsingGETURL
 * method: getClustersUsingGET_TYPE
 * raw_url: getClustersUsingGET_RAW_URL
 * @param country - country
 * @param northEastLatitude - northEastLatitude
 * @param northEastLongitude - northEastLongitude
 * @param southWestLatitude - southWestLatitude
 * @param southWestLongitude - southWestLongitude
 */
export const getClustersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {
    headers: {}
  }
  let path = '/clusters'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['northEastLatitude'] !== undefined) {
    queryParameters['northEastLatitude'] = parameters['northEastLatitude']
  }
  if (parameters['northEastLatitude'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: northEastLatitude'))
  }
  if (parameters['northEastLongitude'] !== undefined) {
    queryParameters['northEastLongitude'] = parameters['northEastLongitude']
  }
  if (parameters['northEastLongitude'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: northEastLongitude'))
  }
  if (parameters['southWestLatitude'] !== undefined) {
    queryParameters['southWestLatitude'] = parameters['southWestLatitude']
  }
  if (parameters['southWestLatitude'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: southWestLatitude'))
  }
  if (parameters['southWestLongitude'] !== undefined) {
    queryParameters['southWestLongitude'] = parameters['southWestLongitude']
  }
  if (parameters['southWestLongitude'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: southWestLongitude'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getClustersUsingGET_RAW_URL = function() {
  return '/clusters'
}
export const getClustersUsingGET_TYPE = function() {
  return 'get'
}
export const getClustersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/clusters'
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['northEastLatitude'] !== undefined) {
    queryParameters['northEastLatitude'] = parameters['northEastLatitude']
  }
  if (parameters['northEastLongitude'] !== undefined) {
    queryParameters['northEastLongitude'] = parameters['northEastLongitude']
  }
  if (parameters['southWestLatitude'] !== undefined) {
    queryParameters['southWestLatitude'] = parameters['southWestLatitude']
  }
  if (parameters['southWestLongitude'] !== undefined) {
    queryParameters['southWestLongitude'] = parameters['southWestLongitude']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllDepartments
 * request: getAllDepartmentsUsingGET
 * url: getAllDepartmentsUsingGETURL
 * method: getAllDepartmentsUsingGET_TYPE
 * raw_url: getAllDepartmentsUsingGET_RAW_URL
 */
export const getAllDepartmentsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {
    headers: {}
  }
  let path = '/departments'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllDepartmentsUsingGET_RAW_URL = function() {
  return '/departments'
}
export const getAllDepartmentsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllDepartmentsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/departments'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDepartment
 * request: getDepartmentUsingGET
 * url: getDepartmentUsingGETURL
 * method: getDepartmentUsingGET_TYPE
 * raw_url: getDepartmentUsingGET_RAW_URL
 * @param country - country
 * @param name - name
 */
export const getDepartmentUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {
    headers: {}
  }
  let path = '/departments/{name}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  path = path.replace('{name}', `${parameters['name']}`)
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDepartmentUsingGET_RAW_URL = function() {
  return '/departments/{name}'
}
export const getDepartmentUsingGET_TYPE = function() {
  return 'get'
}
export const getDepartmentUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/departments/{name}'
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  path = path.replace('{name}', `${parameters['name']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllStores
 * request: getAllStoresUsingGET
 * url: getAllStoresUsingGETURL
 * method: getAllStoresUsingGET_TYPE
 * raw_url: getAllStoresUsingGET_RAW_URL
 * @param city - city
 * @param country - country
 * @param department - department
 * @param latitude - latitude
 * @param longitude - longitude
 * @param page - Page number of the requested page
 * @param size - Size of a page
 * @param sort - Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 */
export const getAllStoresUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {
    headers: {}
  }
  let path = '/stores'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['city'] !== undefined) {
    queryParameters['city'] = parameters['city']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['latitude'] !== undefined) {
    queryParameters['latitude'] = parameters['latitude']
  }
  if (parameters['longitude'] !== undefined) {
    queryParameters['longitude'] = parameters['longitude']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllStoresUsingGET_RAW_URL = function() {
  return '/stores'
}
export const getAllStoresUsingGET_TYPE = function() {
  return 'get'
}
export const getAllStoresUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/stores'
  if (parameters['city'] !== undefined) {
    queryParameters['city'] = parameters['city']
  }
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['department'] !== undefined) {
    queryParameters['department'] = parameters['department']
  }
  if (parameters['latitude'] !== undefined) {
    queryParameters['latitude'] = parameters['latitude']
  }
  if (parameters['longitude'] !== undefined) {
    queryParameters['longitude'] = parameters['longitude']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['size'] !== undefined) {
    queryParameters['size'] = parameters['size']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStoreCount
 * request: getStoreCountUsingGET
 * url: getStoreCountUsingGETURL
 * method: getStoreCountUsingGET_TYPE
 * raw_url: getStoreCountUsingGET_RAW_URL
 * @param country - country
 */
export const getStoreCountUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {
    headers: {}
  }
  let path = '/stores/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters['country'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: country'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStoreCountUsingGET_RAW_URL = function() {
  return '/stores/count'
}
export const getStoreCountUsingGET_TYPE = function() {
  return 'get'
}
export const getStoreCountUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/stores/count'
  if (parameters['country'] !== undefined) {
    queryParameters['country'] = parameters['country']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStore
 * request: getStoreUsingGET
 * url: getStoreUsingGETURL
 * method: getStoreUsingGET_TYPE
 * raw_url: getStoreUsingGET_RAW_URL
 * @param id - id
 */
export const getStoreUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config || {
    headers: {}
  }
  let path = '/stores/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStoreUsingGET_RAW_URL = function() {
  return '/stores/{id}'
}
export const getStoreUsingGET_TYPE = function() {
  return 'get'
}
export const getStoreUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/stores/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
