const constants = {
    ZOOM_DEFAULT: 18,
    ZOOM_SNAP: 0.3,
    ZOOM_DELTA: 0.3,
    MIN_ZOOM: 5.5,
    NB_NEAREST_AGENCIES: 3,
    URL_GOOGLE_MAP:
        'https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i349018013!3m9!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0',
    ZOOM_LEVEL: [2, 4.5, 7, 9, 11, 14.5, 16.5, 18.5],
    MAX_BOUNDS: {
        northEast: { lat: 90, lng: 180 },
        southWest: { lat: -90, lng: -180 }
    }
}

export const URL_NOT_FOUND = '/not-found'

export default constants
