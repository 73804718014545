import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'

import root from '../Sagas'

// render the application
export default (rootReducer) => {
    // create the saga middleware
    const sagaMiddleware = createSagaMiddleware()
    // mount it on the Store
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

    // then run the saga
    const sagasManager = sagaMiddleware.run(root)

    return {
        store,
        sagasManager,
        sagaMiddleware
    }
}
