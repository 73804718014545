import { getUrlVars } from '../Utils/url'

const importGtmTracking = () => import('@nickel/tracking/gtm')

const init = async () => {
    const { gclid } = getUrlVars()
    const gtmTracking = await importGtmTracking()

    if (gclid) {
        sessionStorage.nclid = gclid
    }

    gtmTracking.init(window.REACT_APP_GTM)
}

export default init
