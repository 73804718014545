import * as WSSwagger from '../ws-swagger/ws_swagger'

import agenciesService from './agencies'
import citiesService from './cities'
import clustersService from './clusters'
import departmentsService from './departments'

const create = () => {
    const BASE_URL = window.REACT_APP_API_ENDPOINT
    WSSwagger.setDomain(BASE_URL)
    return {
        ...agenciesService,
        ...departmentsService,
        ...citiesService,
        ...clustersService
    }
}

export default { create }
