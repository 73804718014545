import React from 'react'

import PropTypes from 'prop-types'

import './style.scss'

const H1 = ({ children }) => {
    return (
        <h1 className="cn-title lg orange" itemProp="name">
            {children}
        </h1>
    )
}

H1.propTypes = {
    children: PropTypes.node
}

export default H1
