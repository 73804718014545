import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import ListSchedule from '../../Components/List/schedule'
import Map from '../../Components/Map/agency'
import Section from '../../Components/Section'
import Separator from '../../Components/Separator'
import State from '../../Components/State'
import H1 from '../../Components/Title/H1'
import Constants from '../../Config/Constants'
import { getAgencyNameAndType } from '../../Utils/agency'
import { stringEscapedForUrlMaps, formatPhoneNumber, titleCase } from '../../Utils/Formatted'
import { phone } from '../../Utils/MobileDetect'

import AccountForAll from './Commons/AccountForAll'

class Agency extends Component {
    urlEscaped = () => {
        const { agency } = this.props
        const params = `${agency.address.address} ${agency.address.zipCode} ${agency.address.city}`

        return stringEscapedForUrlMaps(params)
    }

    renderPhoneNumberLink = () => {
        const { agency } = this.props
        if (agency.phoneNumber) {
            const phoneNumber = formatPhoneNumber(agency.phoneNumber, agency.address.country)
            if (phone() !== null)
                return (
                    <a href={`tel:${agency.phoneNumber}`} className="cn-text-default" itemProp="telephone">
                        {phoneNumber}
                    </a>
                )

            return (
                <p className="cn-text-default" itemProp="telephone">
                    {phoneNumber}
                </p>
            )
        }
        return null
    }

    renderState = () => {
        const { agency, t } = this.props
        if (agency.state !== 'UNKNOWN') {
            return (
                <State status={agency.state}>
                    {t('common:close', { context: agency.state === 'OPEN' ? 'open' : '' })}
                </State>
            )
        }

        return null
    }

    renderSchedule = () => {
        const { agency, t } = this.props
        if (agency.openingHoursSchedule && agency.openingHoursSchedule.length > 0) {
            return (
                <Section>
                    <div className="cn-text-default margin-bottom">
                        <b>{t('schedule')}</b>
                    </div>

                    {this.renderState()}

                    <ListSchedule schedule={agency.openingHoursSchedule} />
                </Section>
            )
        }

        return null
    }

    renderMap = () => {
        const { agency } = this.props
        const position = [agency.coordinates.latitude, agency.coordinates.longitude]
        const zoom = Constants.ZOOM_DEFAULT
        return <Map position={position} zoom={zoom} />
    }

    render() {
        const { t, agency } = this.props
        const { agencyType, agencyName } = getAgencyNameAndType(agency)
        const name = agencyName !== agencyType ? `${agencyType} - ${agencyName}` : agencyType
        return (
            <>
                <div className="mdc-layout-grid__inner">
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                        <H1>{name}</H1>
                    </div>
                </div>
                <div className="mdc-layout-grid__inner">
                    <div
                        className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <Section
                                attributes={{
                                    itemProp: 'address',
                                    itemScope: true,
                                    itemType: 'http://schema.org/PostalAddress'
                                }}
                            >
                                <div className="cn-text-default margin-bottom">
                                    <b>{t('contactInfos')}</b>
                                </div>
                                <p className="cn-text-default">
                                    <span itemProp="streetAddress">{titleCase(agency.address.address)}</span>
                                    ,&nbsp;
                                    <span itemProp="postalCode">{agency.address.zipCode}</span>
                                    &nbsp;
                                    <span itemProp="addressLocality">{titleCase(agency.address.city)}</span>
                                </p>
                                {this.renderPhoneNumberLink()}
                            </Section>

                            {this.renderSchedule()}
                        </div>

                        <div className="wrapper-button">
                            <a
                                href={`https://www.google.com/maps/dir/?api=1&destination=${this.urlEscaped()}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cn-button orange"
                            >
                                {t('button:goto')}
                            </a>
                        </div>
                    </div>
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-6">{this.renderMap()}</div>
                </div>

                <Separator />

                <AccountForAll />
            </>
        )
    }
}

Agency.propTypes = {
    t: PropTypes.func,
    agency: PropTypes.shape()
}

export default withNamespaces(['agency'])(Agency)
