import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import BreadcrumbsAction, { BreadcrumbsSelectors } from '../../../../Redux/BreadcrumbsRedux'
import { formatUrl, titleCase } from '../../../../Utils/Formatted'
import { phone } from '../../../../Utils/MobileDetect'
import { getHomePath } from '../../../../Utils/paths'

import './style.scss'

class Breadcrumbs extends PureComponent {
    componentDidMount() {
        this.homePath = getHomePath()
        this.setBreadcrumbs()
    }

    setBreadcrumbs = () => {
        const { setBreadcrumbs, data, t } = this.props
        const breadcrumbs = [
            {
                level: 1,
                link: this.homePath,
                label: t(`common:homeBreadcrumbs`, { context: phone() ? 'mobile' : '' })
            }
        ]
        let incrementPath = this.homePath

        if (data) {
            data.forEach((path, index) => {
                incrementPath = formatUrl(incrementPath, path)
                breadcrumbs.push({
                    level: index + 2,
                    link: incrementPath,
                    label: titleCase(path)
                })
            })
        }

        setBreadcrumbs(breadcrumbs)
    }

    renderIconRight = (index) => {
        const { breadcrumbs } = this.props
        if (typeof breadcrumbs[index + 1] !== 'undefined') {
            return <i className="icon icon-arrow-right-light" />
        }

        return null
    }

    renderLink = (item) => {
        return (
            <Link className="breadcrumbs-item__link" to={item.link} itemProp="item">
                <span itemProp="name">{item.label}</span>
            </Link>
        )
    }

    renderItem = () => {
        const { breadcrumbs } = this.props
        if (breadcrumbs) {
            return breadcrumbs.map((item, index) => (
                <React.Fragment key={item.link}>
                    <li
                        className="breadcrumbs-item"
                        itemProp="itemListElement"
                        itemScope
                        itemType="http://schema.org/ListItem"
                    >
                        {this.renderLink(item)}
                        <meta itemProp="position" content={index + 1} />
                    </li>
                    {this.renderIconRight(index)}
                </React.Fragment>
            ))
        }

        return null
    }

    render() {
        return (
            <ol className="breadcrumbs" itemScope itemType="http://schema.org/BreadcrumbList">
                {this.renderItem()}
            </ol>
        )
    }
}

Breadcrumbs.propTypes = {
    breadcrumbs: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.arrayOf(PropTypes.string),
    t: PropTypes.func,
    setBreadcrumbs: PropTypes.func
}

const mapStateToProps = (state) => ({
    breadcrumbs: BreadcrumbsSelectors.getBreadcrumbs(state)
})

const mapDispatchToProps = (dispatch) => ({
    setBreadcrumbs: (breadcrumbs) => dispatch(BreadcrumbsAction.setBreadcrumbs(breadcrumbs))
})

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(Breadcrumbs))
