import PhoneNumber from 'awesome-phonenumber/index'
import _ from 'lodash'

export const formatUrl = (match, element) => {
    let elm = element
    elm = elm.toLowerCase()
    elm = _.deburr(elm)
    elm = elm.replace(/['\\.()]/g, '')
    elm = elm.split(/[_\s]/).join('-')
    const url = `${match}/${elm}`
    return url.replace('//', '/')
}

export const formatUrlDepartment = (match, element) => {
    let elm = element
    elm = elm.toLowerCase()
    elm = _.deburr(elm)
    elm = elm.replace(/['\\.()]/g, '')
    elm = encodeURIComponent(elm).split(/%2F/g)
    const url = `${match}/${elm}`
    return url.replace('//', '/')
}

export const stringEscapedForUrlMaps = (string) => {
    const newString = string.replace(/\s/g, '+')
    return encodeURI(newString)
}

export const titleCase = (label) => {
    let newLabel = label
    newLabel = newLabel
        .toLowerCase()
        .replace(/([^a-zà-ú]|^)([a-zà-ú])(?=[a-zà-ú]{2})/g, (__, g1, g2) => g1 + g2.toUpperCase())

    newLabel = newLabel.charAt(0).toUpperCase() + newLabel.substr(1)
    return newLabel
}

export const splitUrl = (url) => url.split('/')

export const formatPhoneNumber = (phoneNumber, country) => {
    const pn = new PhoneNumber(phoneNumber, country)
    return pn.getNumber('national')
}

export const formatStringDiacritics = (name) =>
    name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .split(' ')
        .join('-')
        .toLowerCase()
