import { combineReducers } from 'redux'

// import { reducer as formReducer } from 'redux-form'
import { reducer as AddressReducer } from './AddressRedux'
import { reducer as AgencyReducer } from './AgencyRedux'
import { reducer as BreadcrumbsReducer } from './BreadcrumbsRedux'
import { reducer as CityReducer } from './CityRedux'
import { reducer as ClusterReducer } from './ClusterRedux'
import { reducer as CountryReducer } from './CountryRedux'
import configureStore from './CreateStore'
import { reducer as DepartmentReducer } from './DepartmentRedux'
import { reducer as navReducer } from './NavRedux'
import { reducer as UIReducer } from './UIRedux'

/* ------------- Assemble The Reducers ------------- */
export const appReducer = combineReducers({
    nav: navReducer,
    agency: AgencyReducer,
    breadcrumbs: BreadcrumbsReducer,
    country: CountryReducer,
    department: DepartmentReducer,
    city: CityReducer,
    address: AddressReducer,
    cluster: ClusterReducer,
    ui: UIReducer
})

const rootReducer = (state, action) => {
    const newState = state
    return appReducer(newState, action)
}
export default () => {
    const finalReducers = rootReducer
    const { store } = configureStore(finalReducers)

    return store
}
