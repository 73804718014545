import React from 'react'

import PropTypes from 'prop-types'
import { CircleMarker, Tooltip } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'
import './circle-marker.scss'

const CircleMarkerNickel = (props) => {
    const {
        cluster: { count, coordinates, splittingLevel },
        handleOnClick
    } = props

    /**
     * @description Calculate the Radius by the count
     * @returns {number}
     */
    const getRadius = () => {
        if (count > 100) {
            return 30
        }
        if (count >= 10 && count <= 100) {
            return 20
        }
        return 15
    }

    return (
        <CircleMarker
            className="circle-marker"
            center={[coordinates.latitude, coordinates.longitude]}
            radius={getRadius()}
            onClick={() => handleOnClick(coordinates, splittingLevel)}
        >
            <Tooltip className="tooltip" direction="center" permanent>
                <b>{count}</b>
            </Tooltip>
        </CircleMarker>
    )
}

CircleMarkerNickel.propTypes = {
    cluster: PropTypes.shape({
        count: PropTypes.number.isRequired,
        coordinates: PropTypes.shape({
            latitude: PropTypes.number,
            longitude: PropTypes.number
        }).isRequired,
        splittingLevel: PropTypes.number
    }).isRequired,
    handleOnClick: PropTypes.func
}

export default CircleMarkerNickel
