export const handleSuccessResponse = (response) => ({
    ok: true,
    status: response.status,
    data: response.data
})

export const handleErrorResponse = (error) => ({
    ok: false,
    name: error.name,
    originError: error.originError
})
