import React from 'react'

import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import DepartmentScreen from '../Containers/DepartmentScreen'

import CityNavigation from './CityNavigation'

const DepartmentNavigation = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}`} component={DepartmentScreen} />
            <Route path={`${match.url}/:city`} component={CityNavigation} />
        </Switch>
    )
}

DepartmentNavigation.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string
    })
}

export default DepartmentNavigation
