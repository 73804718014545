import React from 'react'

import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import DepartmentListScreen from '../Containers/DepartmentListScreen'
import StoreLocatorScreen from '../Containers/StoreLocatorScreen'
import { getDepartmentListPath } from '../Utils/paths'

import DepartmentNavigation from './DepartmentNavigation'

const StoreLocatorNavigation = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}`} component={StoreLocatorScreen} />
            <Route exact path={`${match.path}/${getDepartmentListPath()}`} component={DepartmentListScreen} />
            <Route path={`${match.path}/:department`} component={DepartmentNavigation} />
        </Switch>
    )
}

StoreLocatorNavigation.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string
    })
}

export default StoreLocatorNavigation
