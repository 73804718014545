import LanguageDetector from 'i18next-browser-languagedetector'

import i18n from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'

import { getCurrentLanguageFromUrl } from '../Utils/i18n'

import deDe from './languages/de_DE'
import enGB from './languages/en_GB'
import enUS from './languages/en_US'
import esES from './languages/es_ES'
import frBE from './languages/fr_BE'
import frFR from './languages/fr_FR'
import nlBE from './languages/nl_BE'
import ptPT from './languages/pt_PT'
import root from './languages/root'

i18n.use(LanguageDetector)
    .init({
        // we init with resources
        resources: {
            [SupportedLocale.ES_ES]: esES,
            [SupportedLocale.FR_FR]: frFR,
            [SupportedLocale.EN_US]: enUS,
            [SupportedLocale.NL_BE]: nlBE,
            [SupportedLocale.FR_BE]: frBE,
            [SupportedLocale.PT_PT]: ptPT,
            [SupportedLocale.DE_DE]: deDe,
            [SupportedLocale.EN_GB]: enGB,
            root
        },
        fallbackLng: 'root',

        // have a common namespace used around the full app
        ns: ['common', 'helmet', 'title', 'button'],
        defaultNS: 'common',

        interpolation: {
            escapeValue: false,
            formatSeparator: ','
        },

        react: {
            wait: true
        }
    })
    .then(() => {
        i18n.changeLanguage(getCurrentLanguageFromUrl())
    })

export default i18n
