import React from 'react'

import { Redirect, Route, Switch } from 'react-router-dom'

import ErrorScreen from '../Containers/404Screen'
import { getRedirectPath, getHomePath } from '../Utils/paths'

import StoreLocatorNavigation from './StoreLocatorNavigation'

const AppNavigation = () => (
    <Switch>
        {getRedirectPath().map((path) => (
            <Route exact key={path} path={path}>
                <Redirect to={getHomePath()} />
            </Route>
        ))}
        <Route path={getHomePath()} component={StoreLocatorNavigation} />
        <Route component={ErrorScreen} />
    </Switch>
)

export default AppNavigation
