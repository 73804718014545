import React, { Component } from 'react'

import lottie from 'lottie-web'

import './style.scss'

class Loader extends Component {
    componentDidMount() {
        lottie.loadAnimation({
            container: this.ref,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            // Put all Json file in Folder Public of project
            path: `${process.env.PUBLIC_URL}/images-annuaire/logo-loader-nickel.json`
        })
    }

    render() {
        // eslint-disable-next-line
        return <div className="wrapper-loader" ref={(ref) => (this.ref = ref)} />
    }
}

export default Loader
