import React, { useEffect, useMemo } from 'react'

import { sortBy } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'

import i18n from '@nickel/i18n'
import useL10n from '@nickel/l10n/useL10n'

import H3 from '../../Components/Title/H3'
import departmentActions, { DepartmentSelectors } from '../../Redux/DepartmentRedux'
import { formatUrlDepartment } from '../../Utils/Formatted'
import { getHomePath } from '../../Utils/paths'
import Breadcrumbs from '../Components/Commons/Breadcrumbs'

import './styles.scss'

const DepartmentListScreen = ({ departments, getDepartmentsRequest }) => {
    useEffect(() => {
        getDepartmentsRequest()
    }, [getDepartmentsRequest])

    const { ISO2 } = useL10n()

    const countryDepartments = useMemo(
        () =>
            Array.isArray(departments)
                ? sortBy(
                      departments.filter((department) => department.country === ISO2),
                      'number'
                  )
                : [],
        [ISO2, departments]
    )

    return (
        <div className="DepartmentList">
            <Breadcrumbs />
            <H3 className="DepartmentList-title">{i18n.t('storeLocator:agencies-by-department')}</H3>
            <div className="DepartmentList-items">
                {countryDepartments.map(({ id, name, number }) => (
                    <Link className="DepartmentList-item" key={id} to={formatUrlDepartment(getHomePath(), name)}>
                        {`${number} - ${name}`}
                    </Link>
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    departments: DepartmentSelectors.getDepartments
})

const mapDispatchToProps = {
    getDepartmentsRequest: departmentActions.getDepartmentsRequest
}

DepartmentListScreen.propTypes = {
    departments: PropTypes.arrayOf(
        PropTypes.shape({
            country: PropTypes.string,
            id: PropTypes.string,
            name: PropTypes.string,
            number: PropTypes.string
        })
    ),
    getDepartmentsRequest: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentListScreen)
