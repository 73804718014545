import { all, takeLatest } from 'redux-saga/effects'

/* ------------- Types ------------- */
import { AddressTypes } from '../Redux/AddressRedux'
import { AgencyTypes } from '../Redux/AgencyRedux'
import { CityTypes } from '../Redux/CityRedux'
import { ClusterTypes } from '../Redux/ClusterRedux'
import { CountryTypes } from '../Redux/CountryRedux'
import { DepartmentTypes } from '../Redux/DepartmentRedux'
import { NavTypes } from '../Redux/NavRedux'
import API from '../Services/Api'
import API_SERVICES from '../Services/ServiceContainer'

import { getAddresses } from './AddressSaga'
import { getAgency, getAgencies, getNearestAgencies, getCountAgencies } from './AgencySaga'
import { getCity, getCityAgencies } from './CitySaga'
import { getClusters } from './ClusterSaga'
import { getCountries } from './CountrySaga'
import { getDepartment, getDepartments, getDepartmentAgencies } from './DepartmentSaga'
import { getNavLinks } from './NavSaga'

const api = API.create()

export default function* root() {
    yield all([
        takeLatest(NavTypes.GET_NAV_LINKS_REQUEST, getNavLinks, API_SERVICES),

        takeLatest(AgencyTypes.GET_AGENCIES_REQUEST, getAgencies, api),
        takeLatest(AgencyTypes.GET_AGENCY_REQUEST, getAgency, api),
        takeLatest(AgencyTypes.GET_NEAREST_AGENCIES_REQUEST, getNearestAgencies, api),
        takeLatest(AgencyTypes.GET_COUNT_AGENCIES_REQUEST, getCountAgencies, api),

        takeLatest(CountryTypes.GET_COUNTRIES_REQUEST, getCountries, api),

        takeLatest(CityTypes.GET_CITY_REQUEST, getCity, api),
        takeLatest(CityTypes.GET_CITY_AGENCIES, getCityAgencies, api),

        takeLatest(DepartmentTypes.GET_DEPARTMENT_REQUEST, getDepartment, api),
        takeLatest(DepartmentTypes.GET_DEPARTMENTS_REQUEST, getDepartments, api),
        takeLatest(DepartmentTypes.GET_DEPARTMENT_AGENCIES, getDepartmentAgencies, api),

        takeLatest(AddressTypes.GET_ADDRESSES_REQUEST, getAddresses, API_SERVICES),

        takeLatest(ClusterTypes.GET_CLUSTERS_REQUEST, getClusters, api)
    ])
}
