import React from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import Agencies from '../../Components/List/agencies'
import Section from '../../Components/Section'
import Separator from '../../Components/Separator'
import H1 from '../../Components/Title/H1'
import { titleCase } from '../../Utils/Formatted'

import AccountForAll from './Commons/AccountForAll'

const City = ({ t, match, city, agencies = [] }) => (
    <>
        <div className="mdc-layout-grid__inner max-width">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <Section>
                    <H1>
                        {titleCase(city.name)}
                        &nbsp;:
                        {t('title:listAgencies')}
                    </H1>
                </Section>
            </div>
        </div>
        <Agencies match={match} agencies={agencies} />
        <Separator />
        <AccountForAll />
    </>
)

City.propTypes = {
    t: PropTypes.func,
    match: PropTypes.shape(),
    city: PropTypes.shape(),
    agencies: PropTypes.arrayOf(PropTypes.object)
}

export default withNamespaces('city')(City)
