import { SupportedLocale } from '@nickel/i18n/lib/types'
import { SupportedCountryISO2 } from '@nickel/l10n/types'

export const languagesAllowed = Object.values(SupportedLocale).filter(
    (language) => language !== SupportedLocale.ROOT && language !== SupportedLocale.ACH_UG
)

const formattedStringToSupportedLocal = (path) => `${path.slice(0, -2).toLowerCase()}${path.slice(-2).toUpperCase()}`

export const getSpecificLanguage = (currentLng) => {
    switch (currentLng) {
        case SupportedLocale.EN_US:
            return 'EN-BE'
        case SupportedLocale.EN_GB:
            return 'EN-FR'
        default:
            return currentLng.toUpperCase()
    }
}

export const getCurrentLanguageFromUrl = () => {
    const countryUrl = formattedStringToSupportedLocal(window.location.pathname.slice(1, 6))
    const cultureUrl = countryUrl.slice(0, 2).toUpperCase()
    const supportedCountryApp = Object.values(SupportedCountryISO2)

    if (languagesAllowed.includes(countryUrl)) {
        return countryUrl
    }

    if (countryUrl === 'en-BE') return SupportedLocale.EN_US
    if (countryUrl === 'en-FR') return SupportedLocale.EN_GB

    if (supportedCountryApp.includes(cultureUrl)) {
        return `${cultureUrl.toLowerCase()}-${cultureUrl}`
    }

    return SupportedLocale.FR_FR
}
