import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Building from '@nickel/ui/components/icons/Building'
import Euro from '@nickel/ui/components/icons/Euro'
import Nickel from '@nickel/ui/components/icons/Nickel'
import NickelLogo from '@nickel/ui/components/icons/NickelLogo'
import Shopping from '@nickel/ui/components/icons/Shopping'

import { NavSelectors } from '../../../../Redux/NavRedux'
import { getNickelPath, getRegistrationPath, getNickelLogo } from '../../../../Utils/paths'
import SelectLanguageModal from '../SelectLanguageModal'

import './style.scss'

const { REACT_APP_ACTIVE_HEADER_RANK = '3' } = window

class Header extends PureComponent {
    state = {
        isPrimaryNavOpen: false
    }

    getClassName = (className) => {
        return this.state.isPrimaryNavOpen ? `${className} active` : className
    }

    withIcon = (index) => {
        const mapIconLink = [
            <Shopping key="shopping" />,
            <Euro key="euro" />,
            <Building key="building" />,
            <span key="logo">
                <NickelLogo />
                <Nickel />
            </span>
        ]

        return mapIconLink[index]
    }

    renderMenuItems = () => {
        const { header } = this.props
        if (header) {
            return header.map((item, index) => {
                const className = item.rank.toString() === REACT_APP_ACTIVE_HEADER_RANK ? 'active' : ''
                return (
                    <li className={`nav-item ${className}`} key={`${item.link}`}>
                        <a className="nav-link" href={item.link}>
                            {this.withIcon(index)}
                            {item.label}
                        </a>
                    </li>
                )
            })
        }
        return null
    }

    renderLinkClientArea = () => {
        const { clientArea } = this.props

        if (!clientArea) return
        return (
            <>
                {!!clientArea.client.link && (
                    <a href={clientArea.client.link} title={clientArea.client.title} className="customer-area-link">
                        {clientArea.client.title}
                    </a>
                )}
                {!!clientArea.connect.title && (
                    <a href={getRegistrationPath()} title={clientArea.connect.title} className="create-account-link">
                        {clientArea.connect.title}
                    </a>
                )}
            </>
        )
    }

    render() {
        return (
            <header className="header">
                <div className="wrapper-nav">
                    <a href={getNickelPath()} rel="home" className="header-logo">
                        <img src={getNickelLogo()} alt="Nickel logo" />
                    </a>

                    <nav className={this.getClassName('wrapper-menu')}>
                        <ul className={this.getClassName('header-nav')}>{this.renderMenuItems()}</ul>

                        <div className={this.getClassName('header-links')}>{this.renderLinkClientArea()}</div>

                        <SelectLanguageModal className={this.getClassName('language-switcher-modal')} />
                    </nav>

                    <button
                        className={this.getClassName('burger-menu')}
                        type="button"
                        onClick={() =>
                            this.setState((prevState) => ({
                                ...prevState,
                                isPrimaryNavOpen: !prevState.isPrimaryNavOpen
                                // eslint-disable-next-line prettier/prettier
                            }))}
                    >
                        <span className="burger-menu-button">
                            <span className="burger-menu-button-text">Menu</span>
                        </span>
                    </button>
                </div>
            </header>
        )
    }
}

Header.propTypes = {
    header: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            link: PropTypes.string,
            rank: PropTypes.number
        })
    )
}

const mapStateToProps = (state) => ({
    header: NavSelectors.getNavLinksHeader(state),
    clientArea: NavSelectors.getNavClientArea(state)
})

export default connect(mapStateToProps, null)(Header)
