import React from 'react'

import L from 'leaflet'
import PropTypes from 'prop-types'
import { Map, TileLayer, Marker, ZoomControl } from 'react-leaflet'

import 'leaflet/dist/leaflet.css'
import './style.scss'

delete L.Icon.Default.prototype._getIconUrl

const MapNickel = ({ position, zoom }) => {
    L.Icon.Default.mergeOptions({
        iconUrl: `${process.env.PUBLIC_URL}/images-annuaire/marker.png`,
        iconRetinaUrl: `${process.env.PUBLIC_URL}/images-annuaire/marker.png`,
        shadowUrl: null
    })
    return (
        <Map
            center={position}
            zoom={zoom}
            attributionControl={false}
            dragging={false}
            zoomControl={false}
            className="map"
            scrollWheelZoom="center"
        >
            <TileLayer
                attribution=""
                url="https://maps.googleapis.com/maps/vt?pb=!1m5!1m4!1i{z}!2i{x}!3i{y}!4i256!2m3!1e0!2sm!3i349018013!3m9!2sen-US!3sUS!5e18!12m1!1e47!12m3!1e37!2m1!1ssmartmaps!4e0"
            />
            <Marker position={position} />
            <ZoomControl position="bottomright" />
        </Map>
    )
}

MapNickel.propTypes = {
    position: PropTypes.arrayOf(PropTypes.number).isRequired,
    zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default MapNickel
