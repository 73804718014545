import React from 'react'

import PropTypes from 'prop-types'

import './style.scss'

const H3 = ({ children }) => {
    return <h3 className="cn-title sm orange">{children}</h3>
}

H3.propTypes = {
    children: PropTypes.node
}

export default H3
