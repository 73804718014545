import { SupportedLocale } from '@nickel/i18n/lib/types'
import { getGlobalISO2 } from '@nickel/l10n/config'
import { SupportedCountryISO2 } from '@nickel/l10n/types'

import flagBe from '../../assets/flag-be.png'
import flagDe from '../../assets/flag-de.png'
import flagEs from '../../assets/flag-es.png'
import flagFr from '../../assets/flag-fr.png'
import flagPt from '../../assets/flag-pt.png'

import { getCurrentLanguageFromUrl } from './i18n'

export const getCountryISO2 = () => {
    const currentLanguage = getCurrentLanguageFromUrl()

    if (currentLanguage === SupportedLocale.EN_US) return SupportedCountryISO2.BE
    if (currentLanguage === SupportedLocale.EN_GB) return SupportedCountryISO2.FR

    return getCurrentLanguageFromUrl().slice(-2)
}

export const mapCountryFlag = {
    [SupportedCountryISO2.BE]: flagBe,
    [SupportedCountryISO2.ES]: flagEs,
    [SupportedCountryISO2.FR]: flagFr,
    [SupportedCountryISO2.PT]: flagPt,
    [SupportedCountryISO2.DE]: flagDe
}

export const countryAllowed = [
    SupportedCountryISO2.BE,
    SupportedCountryISO2.ES,
    SupportedCountryISO2.FR,
    SupportedCountryISO2.PT,
    SupportedCountryISO2.DE
]

const languageSelectable = {
    de: 'de',
    en: 'en',
    es: 'es',
    fr: 'fr',
    nl: 'nl',
    pt: 'pt'
}

export const languageByCountry = {
    [SupportedCountryISO2.BE]: [languageSelectable.fr, languageSelectable.nl, languageSelectable.en],
    [SupportedCountryISO2.ES]: [languageSelectable.es],
    [SupportedCountryISO2.FR]: [languageSelectable.fr, languageSelectable.en],
    [SupportedCountryISO2.PT]: [languageSelectable.pt],
    [SupportedCountryISO2.DE]: [languageSelectable.de]
}

export const getOtherCountryCode = () => {
    const currentCountry = getGlobalISO2()

    return Object.keys(mapCountryFlag).filter((country) => country !== currentCountry)
}
