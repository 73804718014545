import axios from 'axios'

import createL from '@nickel/l10n/createL'

import { getCurrentLanguageFromUrl } from '../../../Utils/i18n'

export default {
    getLinks() {
        return (
            axios
                .get(
                    createL(getCurrentLanguageFromUrl().toUpperCase())({
                        'ES-ES': window.REACT_APP_API_DRUPAL_ENDPOINT_ES,
                        'FR-FR': window.REACT_APP_API_DRUPAL_ENDPOINT_FR,
                        'FR-BE': window.REACT_APP_API_DRUPAL_ENDPOINT_FR_BE,
                        'NL-BE': window.REACT_APP_API_DRUPAL_ENDPOINT_NL_BE,
                        'EN-US': window.REACT_APP_API_DRUPAL_ENDPOINT_EN_BE,
                        'PT-PT': window.REACT_APP_API_DRUPAL_ENDPOINT_PT_PT,
                        'DE-DE': window.REACT_APP_API_DRUPAL_ENDPOINT_DE_DE,
                        'EN-GB': window.REACT_APP_API_DRUPAL_ENDPOINT_EN_FR,
                        default: window.REACT_APP_API_DRUPAL_ENDPOINT_FR
                    })
                )
                .then((resolve) => resolve.data)

                // eslint-disable-next-line
                .catch(error => console.error(error))
        )
    }
}
