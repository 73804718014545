import React, { Component } from 'react'

import { sanitizeUrl } from '@braintree/sanitize-url'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'

import './Styles/layout.scss'
import './Styles/AgencyScreen.scss'

import { URL_NOT_FOUND } from '../Config/Constants'
import AgencyActions, { AgencySelectors } from '../Redux/AgencyRedux'
import { getAgencyNameAndType } from '../Utils/agency'
import { formatUrl, splitUrl, titleCase } from '../Utils/Formatted'
import { hasDepartmentList } from '../Utils/paths'

import Agency from './Components/Agency'
import AppLayout from './Components/Commons/AppLayout'
import Breadcrumbs from './Components/Commons/Breadcrumbs'

class AgencyScreen extends Component {
    componentDidMount() {
        const { match } = this.props
        const params = splitUrl(match.url)
        const idAgency = params[5]
        const split = idAgency.split('-')
        const idPosMatcher = /\d*-\d*-.*/g
        let id
        if (idPosMatcher.exec(idAgency)) {
            id = `${split[0]}-${split[1]}`
        } else {
            id = `${split[0]}`
        }
        this.props.getAgency(id)
    }

    componentDidUpdate() {
        if (!hasDepartmentList()) this.props.history.push(URL_NOT_FOUND)
    }

    renderHelmet = (agency) => {
        const { match, t } = this.props
        const { agencyName } = getAgencyNameAndType(agency)
        let finalUrl = ''
        if (window.location.href.includes(match.url)) {
            finalUrl = window.location.href
        } else {
            const parentUrl = splitUrl(match.url).slice(0, 4).join('/')
            const agencyUrl = formatUrl(parentUrl, `${agency.id}-${agencyName}`)
            finalUrl = `${window.REACT_APP_NICKEL_ENDPOINT}${agencyUrl}`
        }

        return (
            <>
                <Helmet>
                    <meta
                        name="title"
                        content={t(`helmet:agency.title`, { city: agency.address.city, name: titleCase(agencyName) })}
                    />
                    <meta
                        name="description"
                        content={t(`helmet:agency.description`, {
                            zip: agency.address.zipCode,
                            city: agency.address.city,
                            name: titleCase(agencyName)
                        })}
                    />
                    <title>
                        {t(`helmet:agency.title`, { city: agency.address.city, name: titleCase(agencyName) })}
                    </title>
                    <link rel="canonical" href={sanitizeUrl(finalUrl)} />
                </Helmet>
            </>
        )
    }

    renderBreadcrumbs = (agency) => (
        <Breadcrumbs data={[agency.address.department, agency.address.city, getAgencyNameAndType(agency).agencyName]} />
    )

    renderAgency = (agency) => <Agency agency={agency} />

    render() {
        const { agency } = this.props
        if (agency) {
            return (
                <AppLayout>
                    {this.renderHelmet(agency)}
                    <div
                        className="mdc-layout-grid max-width-790"
                        itemProp="provider"
                        itemScope
                        itemType="http://schema.org/FinancialService"
                    >
                        <div className="mdc-layout-grid__inner">
                            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                                {this.renderBreadcrumbs(agency)}
                            </div>
                        </div>

                        {this.renderAgency(agency)}
                    </div>
                </AppLayout>
            )
        }
        return <AppLayout />
    }
}

AgencyScreen.propTypes = {
    t: PropTypes.func,
    agency: PropTypes.shape(),
    match: PropTypes.shape(),
    getAgency: PropTypes.func
}

const mapStateToProps = (state) => ({
    agency: AgencySelectors.getAgency(state)
})

const mapDispatchToProps = (dispatch) => ({
    getAgency: (id) => dispatch(AgencyActions.getAgencyRequest(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(AgencyScreen))
