import React from 'react'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import './style.scss'

const LinkAction = ({ link, children, classes, attributes }) => {
    return (
        <div className="wrapper-button">
            <Link to={link} className={`cn-button ${classes}`} {...attributes}>
                {children}
            </Link>
        </div>
    )
}

LinkAction.propTypes = {
    link: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    attributes: PropTypes.shape()
}

LinkAction.defaultProps = {
    classes: 'orange'
}

export default LinkAction
