import React from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import Section from '../Components/Section'
import H2 from '../Components/Title/H2'
import { getHomePath, getRegistrationPath } from '../Utils/paths'

import './Styles/404Screen.scss'

const ErrorScreen = ({ t }) => (
    <div
        className="mdc-layout-grid max-width buraliste-screen"
        itemProp="provider"
        itemScope
        itemType="http://schema.org/FinancialService"
    >
        <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <div className="center">
                    <H2>{t('notFound.title')}</H2>
                    <Section>
                        <p className="info">{t('notFound.desc')}</p>
                    </Section>

                    <Section>
                        <ul className="menuList">
                            <li className="menuItem">
                                <a href={getHomePath()} className="info cn-link-orange">
                                    {t('common:menus.map')}
                                </a>
                            </li>
                            <li className="menuItem">
                                <a href={getRegistrationPath()} className="info cn-link-orange">
                                    {t('common:menus.openAccount')}
                                </a>
                            </li>
                            <li className="menuItem">
                                <a
                                    href={`${window.REACT_APP_NICKEL_ENDPOINT}/fr/presse`}
                                    className="info cn-link-orange"
                                >
                                    {t('common:menus.mag')}
                                </a>
                            </li>
                            <li className="menuItem">
                                <a
                                    href={`${window.REACT_APP_NICKEL_ENDPOINT}/fr/guide`}
                                    className="info cn-link-orange"
                                >
                                    {t('common:menus.guide')}
                                </a>
                            </li>
                        </ul>
                    </Section>
                </div>
            </div>
        </div>
    </div>
)

ErrorScreen.propTypes = {
    t: PropTypes.func
}

export default withNamespaces(['errors'])(ErrorScreen)
