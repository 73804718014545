import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getCityRequest: ['id'],
    getCitySuccess: ['payload'],
    getCityFailure: ['error'],

    getCityAgencies: ['payload']
})

export const CityTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    city: {
        fetching: false,
        data: null,
        error: null
    }
})

/* ------------- Selectors ------------- */

export const CitySelectors = {
    getCity: (state) => state.city.city.data
}

/* ------------- Reducers ------------- */

const getCityRequest = (state = INITIAL_STATE) => state.merge({ city: { ...state.city, fetching: true } })

const getCitySuccess = (state = INITIAL_STATE, { payload }) => state.merge({ city: { ...state.city, data: payload } })

const getCityFailure = (state = INITIAL_STATE, { error }) => state.merge({ city: { ...state.city, error } })

const getCityAgencies = (state) => state

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_CITY_REQUEST]: getCityRequest,
    [Types.GET_CITY_SUCCESS]: getCitySuccess,
    [Types.GET_CITY_FAILURE]: getCityFailure,

    [Types.GET_CITY_AGENCIES]: getCityAgencies
})
