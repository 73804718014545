import config from '../config'

const MenusService = {
    getLinks() {
        const { drupalVendor } = config
        return drupalVendor.getLinks()
    }
}

export default MenusService
