import React, { Component } from 'react'

import { withNamespaces } from 'react-i18next'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { setGlobalISO2 } from '@nickel/l10n/config'
import L10nProvider from '@nickel/l10n/L10nProvider'
import nickel from '@nickel/ui/themes/nickel'

import Footer from './app/Containers/Components/Commons/Footer'
import Header from './app/Containers/Components/Commons/Header'
import AppNavigation from './app/Navigation/AppNavigation'
import createStore from './app/Redux'
import NavActions from './app/Redux/NavRedux'
import initTracking from './app/tracking/init'
import { getCountryISO2 } from './app/Utils/l10n'

const store = createStore()

class App extends Component {
    componentDidMount() {
        setGlobalISO2(getCountryISO2())
        store.dispatch(NavActions.getNavLinksRequest())
        initTracking()
    }

    render() {
        return (
            <Provider store={store}>
                <ThemeProvider theme={nickel}>
                    <L10nProvider value={getCountryISO2()}>
                        <Header />
                        <AppNavigation />
                        <Footer />
                    </L10nProvider>
                </ThemeProvider>
            </Provider>
        )
    }
}

export default withNamespaces(['common'])(App)
