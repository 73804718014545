import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getNavLinksRequest: null,
    getNavLinksSuccess: ['payload'],
    getNavLinksFailure: ['error']
})

export const NavTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    nav: {
        fetching: false,
        data: {
            header: null,
            footer: null
        },
        error: null
    }
})

/* ------------- Selectors ------------- */

export const NavSelectors = {
    getNavLinksHeader: (state) => state.nav.nav.data.header,
    getNavClientArea: (state) => state.nav.nav.data.client_area,
    getNavLinksFooter: (state) => state.nav.nav.data.footer,
    getNavLinksStore: (state) => state.nav.nav.data.store,
    getNavLinksSocial: (state) => state.nav.nav.data.social,
    getNavLinkFooterButton: (state) => state.nav.nav.data.footer_button,
    getNavFooterText: (state) => state.nav.nav.data.footer_text
}

/* ------------- Reducers ------------- */

const getNavLinksRequest = (state = INITIAL_STATE) => state.merge({ nav: { ...state.nav, fetching: true } })

const getNavLinksSuccess = (state = INITIAL_STATE, { payload }) => state.merge({ nav: { ...state.nav, data: payload } })

const getNavLinksFailure = (state = INITIAL_STATE, { error }) => state.merge({ nav: { ...state.nav, error } })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_NAV_LINKS_REQUEST]: getNavLinksRequest,
    [Types.GET_NAV_LINKS_SUCCESS]: getNavLinksSuccess,
    [Types.GET_NAV_LINKS_FAILURE]: getNavLinksFailure
})
