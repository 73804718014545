import React, { PureComponent } from 'react'

import L from 'leaflet'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { Marker, Popup } from 'react-leaflet'

import { getAgencyNameAndType } from '../../Utils/agency'
import { stringEscapedForUrlMaps, formatUrl, formatPhoneNumber } from '../../Utils/Formatted'
import { phone } from '../../Utils/MobileDetect'
import { hasDepartmentList } from '../../Utils/paths'
import LinkAction from '../Button/link'
import ListSchedule from '../List/schedule'
import Section from '../Section/index'
import State from '../State/index'

import 'leaflet/dist/leaflet.css'
import './marker.scss'

delete L.Icon.Default.prototype._getIconUrl

class MarkerNickel extends PureComponent {
    /**
     * @description Return the correct icon of marker if the agency is open or not
     * @param {string} state State of the agency ex: "OPEN" or "CLOSE"
     * @returns {string} return url of the Marker icon
     */
    urlIconMarker = (state, retina) => {
        let url = ''
        let size = null
        if (state === 'OPEN') {
            url = `${process.env.PUBLIC_URL}/images-annuaire/marker-green${retina ? '3x' : ''}.png`
        } else {
            url = `${process.env.PUBLIC_URL}/images-annuaire/marker-red${retina ? '3x' : ''}.png`
        }
        if (state === null || state === undefined || state === 'UNKNOWN') {
            url = `${process.env.PUBLIC_URL}/images-annuaire/marker${retina ? '3x' : ''}.png`
        }

        if (this.props.geoloc) {
            url = `${process.env.PUBLIC_URL}/images-annuaire/here${retina ? '3x' : ''}.png`
            size = [48, 48]
        }

        return new L.Icon.Default({
            iconUrl: url,
            iconSize: size,
            iconRetinaUrl: url,
            shadowUrl: null
        })
    }

    urlEscaped = () => {
        const { agency } = this.props
        const params = `${agency.address.address} ${agency.address.zipCode} ${agency.address.city}`

        return stringEscapedForUrlMaps(params)
    }

    /**
     * @description Render the agency's phone number different if the user is on a phone or not.
     */
    renderPhoneNumberLink = () => {
        const { agency } = this.props
        if (agency.phoneNumber) {
            const phoneNumber = formatPhoneNumber(agency.phoneNumber, agency.address.country)
            if (phone() !== null)
                return (
                    <a href={`tel:${agency.phoneNumber}`} className="info">
                        {phoneNumber}
                    </a>
                )
            return <p className="info">{phoneNumber}</p>
        }
        return null
    }

    /**
     * @description Construct the agency's url page
     * @returns {string} Return the url to go on agency page
     */
    urlGoToAgency = () => {
        const { match, agency } = this.props
        const { id, address } = agency
        const { agencyName } = getAgencyNameAndType(agency)
        return formatUrl(match.path, `${address.department}/${address.city}/${id}-${agencyName}`)
    }

    renderState = () => {
        const {
            agency: { state },
            t
        } = this.props
        if (state !== 'UNKNOWN') {
            return <State status={state}>{t('common:close', { context: state === 'OPEN' ? 'open' : '' })}</State>
        }
        return null
    }

    renderPopup = () => {
        const { geoloc, t, agency } = this.props
        const { address, openingHoursSchedule } = agency
        const { agencyName } = getAgencyNameAndType(agency)
        if (geoloc) return null
        return (
            // eslint-disable-next-line
            <Popup className="popup" ref="popup">
                <h2 className="title">{agencyName}</h2>
                <Section>
                    <p className="cn-text-default">
                        <span>{`${address.address}, ${address.zipCode} ${address.city}`}</span>
                    </p>
                    {this.renderPhoneNumberLink()}
                </Section>
                {this.renderState()}
                {openingHoursSchedule && openingHoursSchedule.length > 0 && (
                    <ListSchedule schedule={openingHoursSchedule} />
                )}
                <div className="wrapper-buttons">
                    {hasDepartmentList() && (
                        <LinkAction link={this.urlGoToAgency()} classes="cn-button orange">
                            {t('moreInfos')}
                        </LinkAction>
                    )}
                    <a
                        href={`https://www.google.com/maps/dir/?api=1&destination=${this.urlEscaped()}`}
                        className="cn-button border-grey"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        {t('goto')}
                    </a>
                </div>
            </Popup>
        )
    }

    render() {
        const {
            agency: {
                state,
                coordinates: { latitude, longitude }
            },
            handleOnClick
        } = this.props
        return (
            <Marker
                position={[latitude, longitude]}
                onClick={(event) => handleOnClick && handleOnClick(event)}
                icon={this.urlIconMarker(state)}
            >
                {this.renderPopup()}
            </Marker>
        )
    }
}

MarkerNickel.propTypes = {
    agency: PropTypes.shape({
        name: PropTypes.string,
        state: PropTypes.string,
        address: PropTypes.object,
        openingHoursSchedule: PropTypes.array,
        coordinates: PropTypes.shape({
            latitude: PropTypes.number.isRequired,
            longitude: PropTypes.number.isRequired
        }).isRequired
    }).isRequired,
    handleOnClick: PropTypes.func,
    match: PropTypes.shape(),
    geoloc: PropTypes.bool,
    t: PropTypes.func
}

MarkerNickel.defaultProps = {
    geoloc: false
}

export default withNamespaces(['button'])(MarkerNickel)
