import { getGlobalISO2 } from '@nickel/l10n/config'

import { handleErrorResponse, handleSuccessResponse } from '../../Utils/HandlerResponseRequest'
import * as WSSwagger from '../ws-swagger/ws_swagger'

const getClusters = (data) =>
    new Promise((resolve) => {
        WSSwagger.getClustersUsingGET({ ...data, country: getGlobalISO2() })
            .then((response) => {
                resolve(handleSuccessResponse(response))
            })
            .catch((error) => {
                resolve(handleErrorResponse(error))
            })
    })

export default {
    getClusters
}
