import React from 'react'

import PropTypes from 'prop-types'

import './style.scss'

const State = ({ children, status }) => {
    const statusClass = () => {
        switch (status) {
            case 'OPEN':
                return 'open'
            case 'CLOSED':
                return 'close'
            case 'UNKNOWN':
            default:
                return null
        }
    }
    return <span className={`cn-text-default agency-state ${statusClass()}`}>{children}</span>
}

State.propTypes = {
    children: PropTypes.node,
    status: PropTypes.string
}

export default State
