import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    setBreadcrumbs: ['payload']
})

export const BreadcrumbsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    breadcrumbs: null
})

/* ------------- Selectors ------------- */

export const BreadcrumbsSelectors = {
    getBreadcrumbs: (state) => state.breadcrumbs.breadcrumbs
}

/* ------------- Reducers ------------- */

const setBreadcrumbs = (state = INITIAL_STATE, { payload }) => {
    return state.merge({ breadcrumbs: payload })
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_BREADCRUMBS]: setBreadcrumbs
})
