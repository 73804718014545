import React from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import './style.scss'

import H3 from '../../../../Components/Title/H3'
import { getRegistrationPath } from '../../../../Utils/paths'

const AccountForAll = ({ t }) => {
    return (
        <div className="account-for-all">
            <H3>{t('accountForAll')}</H3>

            <p className="cn-text-default">{t('accountForAllText')}</p>

            <div className="wrapper-button">
                <a href={getRegistrationPath()} target="_blank" rel="noopener noreferrer" className="cn-button orange">
                    {t('button:openAccountOnline')}
                </a>
            </div>
        </div>
    )
}

AccountForAll.propTypes = {
    t: PropTypes.func
}

export default withNamespaces()(AccountForAll)
