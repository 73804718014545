import React from 'react'

import es6Promise from 'es6-promise'
import { createBrowserHistory } from 'history'
import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'

import App from './App'
import i18n from './app/I18n'

es6Promise.polyfill()

const history = createBrowserHistory()

history.listen((location) => {
    // Use setTimeout to make sure this runs after React Router's own listener
    setTimeout(() => {
        // Keep default behavior of restoring scroll position when user:
        // - clicked back button
        // - clicked on a link that programmatically calls `history.goBack()`
        // - manually changed the URL in the address bar (here we might want
        // to scroll to top, but we can't differentiate it from the others)
        if (location.action === 'POP') {
            return
        }
        // In all other cases, scroll to top
        window.scrollTo(0, 0)
    })
})

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Router history={history}>
            <App />
        </Router>
    </I18nextProvider>,
    document.getElementById('root')
)
