import React from 'react'

import PropTypes from 'prop-types'

import './style.scss'

const H2 = ({ children }) => {
    return (
        <h2 className="cn-title md" itemProp="name">
            {children}
        </h2>
    )
}

H2.propTypes = {
    children: PropTypes.node
}

export default H2
