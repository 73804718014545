import React from 'react'

import PropTypes from 'prop-types'

import './style.scss'

const Section = ({ attributes, children }) => {
    return (
        <section className="section-infos" {...attributes}>
            {children}
        </section>
    )
}

Section.propTypes = {
    attributes: PropTypes.shape(),
    children: PropTypes.node
}

export default Section
