import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getCountriesRequest: null,
    getCountriesSuccess: ['payload'],
    getCountriesFailure: ['error']
})

export const CountryTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    countries: {
        fetching: false,
        data: null,
        error: null
    }
})

/* ------------- Selectors ------------- */

export const CountrySelectors = {
    getCountries: (state) => state.country.countries.data
}

/* ------------- Reducers ------------- */

const getCountriesRequest = (state = INITIAL_STATE) =>
    state.merge({ countries: { ...state.countries, fetching: true } })

const getCountriesSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ countries: { ...state.countries, data: payload } })

const getCountriesFailure = (state = INITIAL_STATE, { error }) =>
    state.merge({ countries: { ...state.countries, error } })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_COUNTRIES_REQUEST]: getCountriesRequest,
    [Types.GET_COUNTRIES_SUCCESS]: getCountriesSuccess,
    [Types.GET_COUNTRIES_FAILURE]: getCountriesFailure
})
