import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import './schedule.scss'

import { sortDays } from '../../Utils/Date'

const days = sortDays(new Date().getDay())

class ListSchedule extends Component {
    state = {
        isOpen: false,
        error: null
    }

    handleIsOpen = () => {
        return this.state.isOpen ? ' open' : ''
    }

    handleClick = () => {
        try {
            this.setState(({ isOpen }) => ({ isOpen: !isOpen }))
        } catch (error) {
            this.setState({ error })
        }
    }

    renderOpenOrClose = (day) => {
        const { t } = this.props
        if (day.openingHours.length > 0) {
            return day.openingHours.map((hour, hourIndex) => {
                return (
                    // eslint-disable-next-line
                    <React.Fragment key={hourIndex}>
                        <time itemProp={hourIndex % 2 ? 'opens' : 'closes'} content={hour}>
                            {hour.substr(0, hour.length - 3)}
                        </time>
                        {day.openingHours[hourIndex + 1] ? '-' : null}
                    </React.Fragment>
                )
            })
        }

        return t(`common:close`)
    }

    renderItem = () => {
        const { schedule, t } = this.props
        let firstLetterDayCapitalize
        let dayWithoutFirstLetter
        let scheduleOfDay

        try {
            return days.map((day, itemIndex) => {
                firstLetterDayCapitalize = day.charAt(0).toUpperCase()
                dayWithoutFirstLetter = day.slice(1)
                scheduleOfDay = schedule.filter((element) => element.day === day)

                // we select the schedule in order to `days`
                if (scheduleOfDay.length >= 1) {
                    return (
                        // eslint-disable-next-line
                        <li className="list-item" key={itemIndex}>
                            <span className="info day">
                                <link
                                    itemProp="dayOfWeek"
                                    href={`http://schema.org/${firstLetterDayCapitalize}${dayWithoutFirstLetter}`}
                                />
                                {t(`common:days.${scheduleOfDay[0].day}`)}
                                &nbsp;:
                            </span>
                            <span className="info hours">{this.renderOpenOrClose(scheduleOfDay[0])}</span>
                        </li>
                    )
                }
                return null
            })
        } catch (error) {
            return null
        }
    }

    render() {
        if (this.state.error) {
            return <h1>Caught an error.</h1>
        }

        return (
            // eslint-disable-next-line
            <ul
                className={`list schedule icon-a icon-arrow-bottom-light-a${this.handleIsOpen()}`}
                onClick={this.handleClick}
                itemProp="openingHoursSpecification"
                itemScope
                itemType="http://schema.org/OpeningHoursSpecification"
            >
                {this.renderItem()}
            </ul>
        )
    }
}

ListSchedule.propTypes = {
    t: PropTypes.func,
    schedule: PropTypes.arrayOf(PropTypes.object)
}

export default withNamespaces()(ListSchedule)
