import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { URL_NOT_FOUND } from '../Config/Constants'
import AddressActions, { AddressSelectors } from '../Redux/AddressRedux'
import AgencyActions, { AgencySelectors } from '../Redux/AgencyRedux'
import ClusterActions, { ClusterSelectors } from '../Redux/ClusterRedux'
import CountryActions, { CountrySelectors } from '../Redux/CountryRedux'
import { UISelectors } from '../Redux/UIRedux'
import { createBounds } from '../Utils/Map'

import AppLayout from './Components/Commons/AppLayout'
import Breadcrumbs from './Components/Commons/Breadcrumbs'
import StoreLocator from './Components/StoreLocator'

import './Styles/layout.scss'
import './Styles/StoreLocatorScreen.scss'

class StoreLocatorScreen extends Component {
    state = {
        currentBounds: null,
        nearestAgencies: null,
        geolocCoordinates: null
    }

    componentDidMount = () => {
        this.props.getCountries()
        this.props.getCountAgencies()
        if (
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_ES) ||
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_FR) ||
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_FR_BE) ||
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_NL_BE) ||
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_EN_BE) ||
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_PT_PT) ||
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_EN_FR) ||
            document.referrer.endsWith(window.REACT_APP_HOME_PATH_ENDPOINT_DE_DE)
        )
            return
        this.getGeolocation()
    }

    static getDerivedStateFromProps(newProps, prevState) {
        if (
            newProps.nearestAgencies &&
            newProps.nearestAgencies.length > 0 &&
            newProps.nearestAgencies !== prevState.nearestAgencies
        ) {
            return {
                nearestAgencies: newProps.nearestAgencies,
                currentBounds: createBounds(newProps.nearestAgencies)
            }
        }
        return null
    }

    /**
     * @description To transform a datas' list in Links' list to use the component ListLinks
     * @param {array} datas Datas to transform
     * @returns {array}
     */
    transformToListLinks = (datas, type) => {
        if (datas) {
            return datas.map((data) => {
                let newData = data
                switch (type) {
                    case 'CITY':
                        // TODO: Uncomment to change the rule for city
                        // data = {...data, url: `${data.department.name}/${data.name}`}
                        newData = { ...newData, url: newData.name }
                        break
                    case 'DEPARTMENT':
                        newData = { ...newData, url: newData.name }
                        break
                    default:
                        break
                }
                return newData
            })
        }
        return null
    }

    /**
     * @description Get User's Position with Geolocation and display the 3 sales points on the Map
     */
    getGeolocation = () => {
        if (navigator.geolocation && !window.location.search) {
            navigator.geolocation.getCurrentPosition((position) => {
                const geoLocation = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                this.setState({ geolocCoordinates: geoLocation })
                this.props.getNearestAgencies(geoLocation)
                if (!this.mapRef || !this.mapRef.refs || !this.mapRef.refs.leafletMapRef) return
                this.mapRef.refs.leafletMapRef.leafletElement.locate({
                    setView: true,
                    maxZoom: 15
                })
            })
        }
    }

    /**
     * @description Handler to Get new Markers with the new Map bounds
     * @param {objet} bounds New Map bounds
     */
    handleOnChangeCurrentBounds = (bounds) => {
        const requestbounds = {
            southWestLatitude: bounds._southWest.lat,
            southWestLongitude: bounds._southWest.lng,
            northEastLatitude: bounds._northEast.lat,
            northEastLongitude: bounds._northEast.lng
        }
        this.props.getClusters(requestbounds)
        this.setState({ currentBounds: bounds })
    }

    /**
     * @description Handler to get addresses when the user a query in the search input
     */
    handleOnSearchAddress = (query) => {
        this.props.getAddresses({ query })
    }

    /**
     * @description Handler to get nearest agencies by coordinates
     * @param {object} coordinates
     */
    handleGetAgenciesByAddressCoords = (coordinates) => {
        this.props.getNearestAgencies(coordinates)
    }

    renderBreadcrumbs = () => {
        return <Breadcrumbs />
    }

    renderHelmet = () => {
        const { countAgencies, t } = this.props

        return (
            <Helmet>
                <meta name="title" content={t('helmet:store_locator.title')} />
                <meta name="description" content={t('helmet:store_locator.description', { countAgencies })} />
                <title>{t('helmet:store_locator.title')}</title>
            </Helmet>
        )
    }

    render() {
        const { match, countries, addresses, clusters, pending, countAgencies } = this.props
        const { nearestAgencies, currentBounds, geolocCoordinates } = this.state
        if (countries && countries.length > 0) {
            return (
                <AppLayout>
                    {this.renderHelmet()}
                    <div className="mdc-layout-grid max-width-790 store-locator">
                        <StoreLocator
                            match={match}
                            currentBounds={currentBounds}
                            countries={countries}
                            clusters={clusters}
                            addresses={addresses}
                            countAgencies={countAgencies}
                            nearestAgencies={nearestAgencies}
                            pending={pending}
                            geolocCoordinates={geolocCoordinates}
                            handleOnChangeCurrentBounds={this.handleOnChangeCurrentBounds}
                            handleOnSearchAddress={this.handleOnSearchAddress}
                            handleGetAgenciesByAddressCoords={this.handleGetAgenciesByAddressCoords}
                            onRef={(ref) => {
                                this.mapRef = ref
                            }}
                        />
                    </div>
                </AppLayout>
            )
        }
        if (countries && countries.length === 0) return <Redirect to={URL_NOT_FOUND} />

        return null
    }
}

const mapStateToProps = (state) => ({
    countries: CountrySelectors.getCountries(state),
    addresses: AddressSelectors.getAddresses(state),
    nearestAgencies: AgencySelectors.getNearestAgencies(state),
    clusters: ClusterSelectors.getClusters(state),
    pending: UISelectors.pending(state),
    countAgencies: AgencySelectors.getCountAgencies(state)
})

const mapDispatchToProps = (dispatch) => ({
    getCountries: () => dispatch(CountryActions.getCountriesRequest()),
    getAddresses: (data) => dispatch(AddressActions.getAddressesRequest(data)),
    getNearestAgencies: (data) => dispatch(AgencyActions.getNearestAgenciesRequest(data)),
    getClusters: (data) => dispatch(ClusterActions.getClustersRequest(data)),
    getCountAgencies: () => dispatch(AgencyActions.getCountAgenciesRequest())
})

StoreLocatorScreen.propTypes = {
    countries: PropTypes.arrayOf(PropTypes.object),
    clusters: PropTypes.arrayOf(PropTypes.object),
    addresses: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.shape(),
    t: PropTypes.func,
    getCountries: PropTypes.func,
    getAddresses: PropTypes.func,
    getNearestAgencies: PropTypes.func,
    getClusters: PropTypes.func,
    getCountAgencies: PropTypes.func,
    pending: PropTypes.bool,
    countAgencies: PropTypes.number
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces(['storeLocator'])(StoreLocatorScreen))
