import { call, put } from 'redux-saga/effects'

import ClustersActions from '../Redux/ClusterRedux'
import UIActions from '../Redux/UIRedux'

export function* getClustersBusiness(api, { payload }) {
    const response = yield call(api.getClusters, payload)
    if (response.ok) {
        yield put(ClustersActions.getClustersSuccess(response.data))
    } else {
        yield put(ClustersActions.getClustersFailure(response.error))
    }
}

export function* getClusters(api, { payload }) {
    yield put(UIActions.showPender())

    yield call(getClustersBusiness, api, { payload })

    yield put(UIActions.hidePender())
}
