/* eslint-disable */
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withNamespaces } from 'react-i18next'
import L from 'leaflet'
import PropTypes from 'prop-types'

import { getGlobalISO2 } from '@nickel/l10n/config'

import { Flag } from '../../flags/constants'
import { getDepartmentListPath, getHomePath, hasDepartmentList } from '../../Utils/paths'
import { isActiveFlag } from '../../flags'
import AccountForAll from './Commons/AccountForAll'
import CircleMarkerNickel from '../../Components/Marker/CircleMarker'
import constants from '../../Config/Constants'
import H3 from '../../Components/Title/H3'
import Loader from '../../Components/Loader'
import Map from '../../Components/Map/StoreLocator'
import MarkerNickel from '../../Components/Marker/Marker'
import SearchBar from '../../Components/Search'
import Separator from '../../Components/Separator'
import { getOtherCountryCode, mapCountryFlag } from '../../Utils/l10n'
import { formatStringDiacritics } from '../../Utils/Formatted'

let timeout = null

const countryIdMap = {
    BE: '3',
    ES: '2',
    FR: '1',
    PT: '4',
    DE: '5'
}

class StoreLocator extends Component {
    state = {
        currentCountry: null,
        currentTerritory: null,
        isSearchEnabled: false,
        isVisibleCluster: true,
        mapLoaderIsTimeout: false
    }

    shouldComponentUpdate(nextProps) {
        if (nextProps.clusters !== this.props.clusters) {
            this.setState({
                isVisibleCluster: true
            })
        }
        return true
    }

    componentDidUpdate = () => {
        this.defineMapLoader()
    }

    componentDidMount = () => {
        const { countries } = this.props
        const currentCountry = countries.find((country) => country.id === countryIdMap[getGlobalISO2()])
        this.initState(currentCountry)
    }

    /**
     * @description Initialize the current Component's states
     * @param {object} currentCountry country of countries JSON to set the state currentCountry
     */
    initState = (currentCountry) => {
        let defaultTerritory = currentCountry.mainTerritoryId
        if (currentCountry.territories.length > 1 && !!window.location.search) {
            const url = window.location.search.replace('?', '')
            currentCountry.territories.forEach((territory) => {
                if (url === formatStringDiacritics(territory.name)) {
                    defaultTerritory = territory.id
                }
            })
        }

        const { currentBounds, currentTerritory, isSearchEnabled } = this.setCurrentState(
            currentCountry,
            defaultTerritory
        )
        this.setState({ currentCountry, currentTerritory, isSearchEnabled })
        this.props.handleOnChangeCurrentBounds(currentBounds)
    }

    /**
     * @description Change the current state currentTerritory and props currentBounds with the handler
     * @param {string} territoryId territory's Id that we want to put in the state: currentTerritory
     */
    changeCurrentState = (territoryId) => {
        const { currentCountry } = this.state
        const { currentBounds, currentTerritory } = this.setCurrentState(currentCountry, territoryId)
        this.setState({ currentTerritory })
        // Call handler to update the markers where the current bounds has changed
        this.props.handleOnChangeCurrentBounds(currentBounds)
    }

    /**
     * @description Set current states: currentBounds and currentTerritory
     * @param {object} country country of countries JSON
     * @param {string} territoryId Territory's Id that we want to get new bounds and territory
     * @returns {object} Return an object of {currentBounds: {}, currentTerritory: {}, isSearchEnabled: boolean}
     */
    setCurrentState = (country, territoryId) => {
        let currentBounds
        let currentTerritory
        let isSearchEnabled = country.id === countryIdMap.FR
        country.territories.forEach((territory) => {
            if (territoryId === territory.id) {
                currentBounds = {
                    _northEast: {
                        lat: territory.upperRightCoordinates.latitude,
                        lng: territory.upperRightCoordinates.longitude
                    },
                    _southWest: {
                        lat: territory.lowerLeftCoordinates.latitude,
                        lng: territory.lowerLeftCoordinates.longitude
                    }
                }
                currentTerritory = territory
            }
        })
        return { currentBounds, currentTerritory, isSearchEnabled }
    }

    /**
     * @description Handler on click to put the circle marker in Map's center
     * @param {object} event Marker's event
     */
    handleOnClickCircleMarker = (coordinates, splittingLevel) => {
        const position = [coordinates.latitude, coordinates.longitude]
        const { leafletElement } = this.mapRef.refs.leafletMapRef
        this.setState({ isVisibleCluster: false })
        const nextZoom = constants.ZOOM_LEVEL[splittingLevel - 1]
        leafletElement.setView(position, nextZoom)
    }

    /**
     * @description Handler on click to put the Marker in bottom of the Map to display the popup correctly
     * @param {object} event  Marker's event
     * @param {object} agency The agency that has just been clicked
     */
    handleOnclickMarker = (event, agency = {}) => {
        const { leafletMapRef } = this.mapRef.refs
        const mapCurrentCenter = leafletMapRef.leafletElement.getCenter()
        const mapCurrentBounds = leafletMapRef.leafletElement.getBounds()
        // calcul of 10% of map size on latitude to make a margin between Map bottom and marker bottom
        const marginBottom = (mapCurrentBounds._northEast.lat - mapCurrentBounds._southWest.lat) * 0.1
        // get latitude of marker and Map bottom
        const markerLatitude = event.latlng.lat
        const mapSouthlatitude = mapCurrentBounds._southWest.lat
        // Calcul of new Map Center Latitude: Difference between latitude of Marker and Map bottom, we apply marginBottom and we add the total to the current Map center to up the Map bottom on the Marker
        const newMapCenterLatitude = markerLatitude - mapSouthlatitude - marginBottom + mapCurrentCenter.lat
        const newMapView = L.latLng(newMapCenterLatitude, event.latlng.lng)
        // Update the map view with the new coordinates
        leafletMapRef.leafletElement.setView(newMapView)
    }

    renderGeolocMarker = () => {
        const { geolocCoordinates } = this.props
        if (geolocCoordinates) return <MarkerNickel agency={{ coordinates: geolocCoordinates }} geoloc />

        return null
    }

    renderClustersNickel = () => {
        const { match, clusters } = this.props
        if (clusters && this.state.isVisibleCluster) {
            return clusters.map((cluster, index) => {
                if (cluster.uniqueStoreView) {
                    const agency = cluster.uniqueStoreView
                    const handleOnClick = (event) => this.handleOnclickMarker(event, agency)
                    return <MarkerNickel agency={agency} match={match} handleOnClick={handleOnClick} key={index} />
                }
                return (
                    <CircleMarkerNickel cluster={cluster} handleOnClick={this.handleOnClickCircleMarker} key={index} />
                )
            })
        }
        return null
    }

    renderMapLoader = () => {
        return (
            <div className="map-loader">
                <Loader />
            </div>
        )
    }

    defineMapLoader = () => {
        const { pending } = this.props
        if (pending && !timeout) timeout = setTimeout(() => this.setState({ mapLoaderIsTimeout: true }), 50)

        if (!pending && timeout) {
            clearTimeout(timeout)
            timeout = null
            this.setState({ mapLoaderIsTimeout: false })
        }
    }

    renderMap = () => {
        const { match, currentBounds, handleOnChangeCurrentBounds } = this.props
        const { currentTerritory, mapLoaderIsTimeout } = this.state

        if (currentTerritory && currentBounds) {
            return (
                <div className="wrapper-map">
                    <Map
                        match={match}
                        bounds={currentBounds}
                        key={currentTerritory.id}
                        handleOnChangeCurrentBounds={handleOnChangeCurrentBounds}
                        onRef={(ref) => {
                            this.mapRef = ref
                            this.props.onRef(ref)
                        }}
                    >
                        {this.renderClustersNickel()}
                        {this.renderGeolocMarker()}
                    </Map>
                    {mapLoaderIsTimeout && this.renderMapLoader()}
                </div>
            )
        }
        return null
    }

    renderDom = () => {
        const { currentCountry, currentTerritory } = this.state
        const { t } = this.props
        if (!currentCountry) return null

        if (currentCountry.territories.length <= 1) return null

        const buttons = currentCountry.territories.map((territory) => (
            <button
                className={`dom-item ${currentTerritory && currentTerritory.id === territory.id ? 'active' : ''}`}
                key={territory.id}
                onClick={() => this.changeCurrentState(territory.id)}
            >
                {t(`territories-button.${currentCountry.country}.${territory.name}`)}
            </button>
        ))

        return buttons
    }

    renderDomCountry = () => {
        if (!isActiveFlag(Flag.I18N)) return null

        const otherCountryCode = getOtherCountryCode()

        return otherCountryCode.map((country) => {
            const countryEndPoint = () => {
                switch (country) {
                    case 'BE':
                        return 'FR_BE'
                    case 'PT':
                        return 'PT_PT'
                    case 'DE':
                        return 'DE_DE'
                    default:
                        return country
                }
            }
            return (
                <a
                    className="dom-item dom-item--withFlag"
                    key={country}
                    href={window[`REACT_APP_HOME_PATH_ENDPOINT_${countryEndPoint()}`]}
                >
                    <img src={mapCountryFlag[country]} />
                    {this.props.t(`country.${country}`)}
                </a>
            )
        })
    }

    renderCountAgencies = () => {
        const { countAgencies, t } = this.props
        const { isSearchEnabled } = this.state
        if (countAgencies) {
            return (
                <h3 className={`sale-points ${isSearchEnabled ? 'title' : ''}`}>
                    {t('availableIn')} <b className="amount">{countAgencies}</b> {t('pointsOfSale')}
                </h3>
            )
        }

        return null
    }

    render() {
        const { handleOnSearchAddress, addresses, handleGetAgenciesByAddressCoords, t } = this.props
        const { isSearchEnabled } = this.state
        return (
            <React.Fragment>
                <div className="mdc-layout-grid__inner">
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-tablet mdc-layout-grid__cell--span-6-desktop cell-center--span-6 content-center">
                        {this.renderCountAgencies()}
                    </div>
                </div>
                {isSearchEnabled && (
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-8-desktop cell-center--span-8">
                            <SearchBar
                                addresses={addresses}
                                handleOnSearchAddress={handleOnSearchAddress}
                                handleGetAgenciesByAddressCoords={handleGetAgenciesByAddressCoords}
                            />
                        </div>
                    </div>
                )}
                <div className="dom">{this.renderDom()}</div>
                <div className="dom">{this.renderDomCountry()}</div>
                <div className="mdc-layout-grid__inner">
                    <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">{this.renderMap()}</div>
                </div>

                {hasDepartmentList() && (
                    <H3>
                        <Link to={`${getHomePath()}/${getDepartmentListPath()}`}>{t('agencies-by-department')}</Link>
                    </H3>
                )}
                <Separator />
                <AccountForAll />
            </React.Fragment>
        )
    }
}

StoreLocator.propTypes = {
    countries: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            country: PropTypes.string,
            mainTerritoryId: PropTypes.string,
            territories: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string,
                    name: PropTypes.string,
                    upperRightCoordinates: PropTypes.shape({
                        latitude: PropTypes.number,
                        longitude: PropTypes.number
                    }),
                    lowerLeftCoordinates: PropTypes.shape({
                        latitude: PropTypes.number,
                        longitude: PropTypes.number
                    })
                })
            )
        })
    ).isRequired,
    currentBounds: PropTypes.shape(),
    clusters: PropTypes.arrayOf(PropTypes.object),
    addresses: PropTypes.arrayOf(),
    countAgencies: PropTypes.number,
    handleOnChangeCurrentBounds: PropTypes.func,
    handleOnSearchAddress: PropTypes.func,
    handleGetAgenciesByAddressCoords: PropTypes.func,
    geolocCoordinates: PropTypes.shape(),
    match: PropTypes.shape(),
    t: PropTypes.func
}

export default withNamespaces(['storeLocator'])(StoreLocator)
