/**
 * @description Create Bounds for a Map with Marker's and apply a Margin
 * @param {array} nearestAgencies
 */
export const createBounds = (nearestAgencies) => {
    const bounds = {
        _northEast: {
            lat: nearestAgencies[0].coordinates.latitude,
            lng: nearestAgencies[0].coordinates.longitude
        },
        _southWest: {
            lat: nearestAgencies[0].coordinates.latitude,
            lng: nearestAgencies[0].coordinates.longitude
        }
    }
    // construct the bounds with the two farest points
    nearestAgencies.forEach((marker) => {
        if (bounds._northEast.lat < marker.coordinates.latitude) {
            bounds._northEast.lat = marker.coordinates.latitude
        }
        if (bounds._northEast.lng > marker.coordinates.longitude) {
            bounds._northEast.lng = marker.coordinates.longitude
        }
        if (bounds._southWest.lat > marker.coordinates.latitude) {
            bounds._northEast.lat = marker.coordinates.latitude
        }
        if (bounds._southWest.lng < marker.coordinates.longitude) {
            bounds._southWest.lng = marker.coordinates.longitude
        }
    })
    let marginLatitude = 0.1
    let marginlongitude = 0.1
    // verify if it a bound and not a point
    if (bounds._northEast.lat !== bounds._southWest.lat && bounds._northEast.lng !== bounds._southWest.lng) {
        // apply 10% of margin for the bounds
        marginLatitude = (bounds._northEast.lat - bounds._southWest.lat) * 0.1
        marginlongitude = (bounds._southWest.lng - bounds._northEast.lng) * 0.1
    }
    bounds._northEast.lat += marginLatitude
    bounds._southWest.lat -= marginLatitude
    bounds._northEast.lng -= marginlongitude
    bounds._southWest.lng += marginlongitude

    return bounds
}
