import React from 'react'

import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'

import AgencyScreen from '../Containers/AgencyScreen'
import CityScreen from '../Containers/CityScreen'

const CityNavigation = ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.path}`} component={CityScreen} />
            <Route path={`${match.url}/:name`} component={AgencyScreen} />
        </Switch>
    )
}

CityNavigation.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string
    })
}

export default CityNavigation
