import { call, put } from 'redux-saga/effects'

import { getGlobalISO2 } from '@nickel/l10n/config'

import constants from '../Config/Constants'
import AgencyActions from '../Redux/AgencyRedux'
import UIActions from '../Redux/UIRedux'

export function* getAgencyBusiness(api, id) {
    const response = yield call(api.getAgency, id)
    if (response.ok) {
        yield put(AgencyActions.getAgencySuccess(response.data))
    } else {
        yield put(AgencyActions.getAgencyFailure(response.error))
    }
}

export function* getAgenciesBusiness(api, { payload }) {
    const response = yield call(api.getAllAgencies, { ...payload })
    if (response.ok) {
        yield put(AgencyActions.getAgenciesSuccess(response.data))
    } else {
        yield put(AgencyActions.getAgenciesFailure(response.error))
    }
}

export function* getNearestAgenciesBusiness(api, { payload }) {
    const response = yield call(api.getAllAgencies, { ...payload, size: constants.NB_NEAREST_AGENCIES })
    if (response.ok) {
        yield put(AgencyActions.getNearestAgenciesSuccess(response.data))
    } else {
        yield put(AgencyActions.getNearestAgenciesFailure(response.error))
    }
}

export function* getCountAgencies(api) {
    const currentISO2 = getGlobalISO2()
    const response = yield call(api.getStoreCount, { country: currentISO2 })
    if (response.ok) {
        yield put(AgencyActions.getCountAgenciesSuccess(response.data))
    } else {
        yield put(AgencyActions.getCountAgenciesFailure(response.error))
    }
}

export function* getAgency(api, id) {
    yield put(UIActions.showLoader())

    yield call(getAgencyBusiness, api, id)

    yield put(UIActions.hideLoader())
}

export function* getAgencies(api, { payload }) {
    yield put(UIActions.showLoader())

    yield call(getAgenciesBusiness, api, { payload })

    yield put(UIActions.hideLoader())
}

export function* getNearestAgencies(api, { payload }) {
    yield put(UIActions.showPender())

    yield call(getNearestAgenciesBusiness, api, { payload })

    yield put(UIActions.hidePender())
}
