import React, { PureComponent } from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import './style.scss'

class SearchBar extends PureComponent {
    state = {
        searchText: '',
        focused: false,
        typingTimeout: 0
    }

    /**
     * @description Handler To search a list of addresses when the user write in the input search, We launch the search 1 seconde after the user stop to write in the input search
     * @param {object} event
     */
    handleOnSearchAddress = (event) => {
        const { handleOnSearchAddress } = this.props
        const { typingTimeout } = this.state
        if (typingTimeout) clearTimeout(typingTimeout)
        const searchText = event.target.value
        this.setState({
            searchText,
            typingTimeout: setTimeout(() => {
                if (searchText && searchText.trim() !== '') handleOnSearchAddress(searchText)
            }, 1000)
        })
    }

    /**
     * @description Handler to return the open class when there're a result to the search
     * @returns {string}
     */
    handleIsResults = () => {
        const { addresses } = this.props
        const { focused } = this.state
        if (focused && addresses && addresses.length > 1) {
            return 'open'
        }
        return ''
    }

    /**
     * @description Handler to change input's label after a click on the result's search
     * @param {object} address
     */
    handleOnClick = (address) => {
        this.setState({
            focused: false,
            searchText: address.properties.label
        })
        const coordinates = {
            longitude: address.geometry.coordinates[0],
            latitude: address.geometry.coordinates[1]
        }
        this.props.handleGetAgenciesByAddressCoords(coordinates)
    }

    /**
     * @description Handler to change input's label with the first address of result when the user click on enter keyboard
     * @param {object} event
     */
    handleKeyDown = (event) => {
        const { addresses } = this.props
        if (addresses && addresses.length > 0 && event.keyCode === 13) this.handleOnClick(addresses[0])
    }

    renderResultSearch = () => {
        const { addresses } = this.props
        if (addresses) {
            return addresses.map((address) => {
                return (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <li
                        className="list-item"
                        key={address.properties.id}
                        onMouseDown={() => this.handleOnClick(address)}
                        onTouchStart={() => this.handleOnClick(address)}
                    >
                        <span>{address.properties.label}</span>
                    </li>
                )
            }, this)
        }
        return null
    }

    render() {
        const { t } = this.props
        const { searchText } = this.state
        return (
            <div className="wrapper-search">
                <div className="search-bar">
                    <input
                        type="text"
                        id="searchAgencies"
                        placeholder={t('yourSearchHere')}
                        onChange={(event) => this.handleOnSearchAddress(event)}
                        onFocus={() => {
                            this.setState({ focused: true })
                        }}
                        onBlur={() => {
                            this.setState({ focused: false })
                        }}
                        onKeyDown={(event) => this.handleKeyDown(event)}
                        value={searchText}
                    />
                    <button type="button">
                        <i className="icon icon-search" />
                    </button>
                </div>
                <div className={`search-result ${this.handleIsResults()}`}>
                    <ul className="list-address">{this.renderResultSearch()}</ul>
                </div>
            </div>
        )
    }
}

SearchBar.propTypes = {
    addresses: PropTypes.arrayOf(
        PropTypes.shape({
            geometry: PropTypes.shape({
                coodinates: PropTypes.arrayOf(PropTypes.number),
                type: PropTypes.string
            }),
            properties: PropTypes.shape({
                id: PropTypes.string,
                label: PropTypes.string
            })
        })
    ),
    handleOnSearchAddress: PropTypes.func,
    handleGetAgenciesByAddressCoords: PropTypes.func,
    t: PropTypes.func
}

export default withNamespaces(['storeLocator'])(SearchBar)
