import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

import { getHomePath } from '../Utils/paths'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getAgenciesRequest: ['payload'],
    getAgenciesSuccess: ['payload'],
    getAgenciesFailure: ['error'],

    getAgencyRequest: ['id'],
    getAgencySuccess: ['payload'],
    getAgencyFailure: ['error'],

    getNearestAgenciesRequest: ['payload'],
    getNearestAgenciesSuccess: ['payload'],
    getNearestAgenciesFailure: ['error'],

    getCountAgenciesRequest: null,
    getCountAgenciesSuccess: ['payload'],
    getCountAgenciesFailure: ['error']
})

export const AgencyTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    agencies: {
        fetching: false,
        data: null,
        error: null
    },
    agency: {
        fetching: false,
        data: null,
        error: null
    },
    nearestAgencies: {
        fetching: false,
        data: null,
        error: null
    },
    countAgencies: {
        fetching: false,
        data: null,
        error: null
    }
})

/* ------------- Selectors ------------- */

export const AgencySelectors = {
    getAgencies: (state) => state.agency.agencies.data,
    getNearestAgencies: (state) => state.agency.nearestAgencies.data,
    getAgency: (state) => state.agency.agency.data,
    getCountAgencies: (state) => state.agency.countAgencies.data
}

/* ------------- Reducers ------------- */

const getAgenciesRequest = (state = INITIAL_STATE) => state.merge({ agencies: { ...state.agencies, fetching: true } })

const getAgenciesSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ agencies: { ...state.agencies, data: payload } })

const getAgenciesFailure = (state = INITIAL_STATE, { error }) => state.merge({ agencies: { ...state.agencies, error } })

const getAgencyRequest = (state = INITIAL_STATE) => state.merge({ agency: { ...state.agency, fetching: true } })

const getAgencySuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ agency: { ...state.agency, data: payload } })

const getAgencyFailure = (state = INITIAL_STATE, { error }) => {
    window.location.replace(getHomePath())
    return state.merge({ agency: { ...state.agency, error } })
}

const getNearestAgenciesRequest = (state = INITIAL_STATE) =>
    state.merge({ nearestAgencies: { ...state.nearestAgencies, fetching: true } })

const getNearestAgenciesSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ nearestAgencies: { ...state.nearestAgencies, data: payload } })

const getNearestAgenciesFailure = (state = INITIAL_STATE, { error }) =>
    state.merge({ nearestAgencies: { ...state.nearestAgencies, error } })

const getCountAgenciesRequest = (state = INITIAL_STATE) =>
    state.merge({ countAgencies: { ...state.countAgencies, fetching: true } })

const getCountAgenciesSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ countAgencies: { ...state.countAgencies, data: payload.count } })

const getCountAgenciesFailure = (state = INITIAL_STATE, { error }) =>
    state.merge({ countAgencies: { ...state.countAgencies, error } })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_AGENCIES_REQUEST]: getAgenciesRequest,
    [Types.GET_AGENCIES_SUCCESS]: getAgenciesSuccess,
    [Types.GET_AGENCIES_FAILURE]: getAgenciesFailure,

    [Types.GET_AGENCY_REQUEST]: getAgencyRequest,
    [Types.GET_AGENCY_SUCCESS]: getAgencySuccess,
    [Types.GET_AGENCY_FAILURE]: getAgencyFailure,

    [Types.GET_NEAREST_AGENCIES_REQUEST]: getNearestAgenciesRequest,
    [Types.GET_NEAREST_AGENCIES_SUCCESS]: getNearestAgenciesSuccess,
    [Types.GET_NEAREST_AGENCIES_FAILURE]: getNearestAgenciesFailure,

    [Types.GET_COUNT_AGENCIES_REQUEST]: getCountAgenciesRequest,
    [Types.GET_COUNT_AGENCIES_SUCCESS]: getCountAgenciesSuccess,
    [Types.GET_COUNT_AGENCIES_FAILURE]: getCountAgenciesFailure
})
