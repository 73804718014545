import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'

import './Styles/layout.scss'

import { URL_NOT_FOUND } from '../Config/Constants'
import { AgencySelectors } from '../Redux/AgencyRedux'
import CityActions, { CitySelectors } from '../Redux/CityRedux'
import { splitUrl, titleCase } from '../Utils/Formatted'
import { hasDepartmentList } from '../Utils/paths'

import City from './Components/City'
import AppLayout from './Components/Commons/AppLayout'
import Breadcrumbs from './Components/Commons/Breadcrumbs'

class CityScreen extends Component {
    componentDidMount() {
        const { match } = this.props
        const params = splitUrl(match.url)
        this.props.getCityAgencies(params[4], { department: params[3], city: params[4] })
    }

    componentDidUpdate() {
        const { agencies, city } = this.props
        if (agencies && agencies.length === 0 && city === null) this.props.history.push(URL_NOT_FOUND)
        if (!hasDepartmentList()) this.props.history.push(URL_NOT_FOUND)
    }

    renderHelmet = (city) => {
        const { t } = this.props

        return (
            <>
                <Helmet>
                    <meta name="title" content={t('helmet:city.title', { city: titleCase(city.name) })} />
                    <meta name="description" content={t('helmet:city.description', { city: titleCase(city.name) })} />
                    <title>{t('helmet:city.title', { city: titleCase(city.name) })}</title>
                </Helmet>
            </>
        )
    }

    renderBreadcrumbs = (city) => {
        return <Breadcrumbs data={[city.department.name, city.name]} />
    }

    render() {
        const { match, city, agencies } = this.props
        if (!city) return null
        return (
            <AppLayout>
                {this.renderHelmet(city)}
                <div
                    className="mdc-layout-grid max-width-1200"
                    itemProp="provider"
                    itemScope
                    itemType="http://schema.org/FinancialService"
                >
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            {this.renderBreadcrumbs(city)}
                        </div>
                    </div>

                    <City match={match} city={city} agencies={agencies} />
                </div>
            </AppLayout>
        )
    }
}

CityScreen.propTypes = {
    match: PropTypes.shape(),
    history: PropTypes.shape(),
    t: PropTypes.func,
    city: PropTypes.shape(),
    agencies: PropTypes.arrayOf(PropTypes.object),
    getCityAgencies: PropTypes.func
}

const mapStateToProps = (state) => ({
    city: CitySelectors.getCity(state),
    agencies: AgencySelectors.getAgencies(state)
})

const mapDispatchToProps = (dispatch) => ({
    getCityAgencies: (id, data) => dispatch(CityActions.getCityAgencies({ id, data }))
})

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(CityScreen))
