import { getGlobalISO2 } from '@nickel/l10n/config'

import { handleErrorResponse, handleSuccessResponse } from '../../Utils/HandlerResponseRequest'
import * as WSSwagger from '../ws-swagger/ws_swagger'

const getOneCity = ({ id }) =>
    new Promise((resolve) => {
        WSSwagger.getCityUsingGET({ country: getGlobalISO2(), name: id })
            .then((response) => {
                resolve(handleSuccessResponse(response))
            })
            .catch((error) => {
                resolve(handleErrorResponse(error))
            })
    })

export default {
    getOneCity
}
