import { put, call } from 'redux-saga/effects'

import CityActions from '../Redux/CityRedux'
import UIActions from '../Redux/UIRedux'

import { getAgenciesBusiness } from './AgencySaga'

export function* getCityBusiness(api, { id }) {
    const response = yield call(api.getOneCity, { id })
    if (response.ok) {
        yield put(CityActions.getCitySuccess(response.data))
    } else {
        yield put(CityActions.getCityFailure(response.error))
    }
}

export function* getCity(api, { id }) {
    yield put(UIActions.showLoader())

    yield call(getCityBusiness, api, { id })

    yield put(UIActions.hideLoader())
}

export function* getCityAgencies(api, { payload }) {
    yield put(UIActions.showLoader())

    yield call(getCityBusiness, api, { id: payload.id })
    yield call(getAgenciesBusiness, api, { payload: payload.data })

    yield put(UIActions.hideLoader())
}
