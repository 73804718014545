import axios from 'axios'

const API_DATA_GOUV_ENDPOINT = `https://api-adresse.data.gouv.fr/`

export default {
    autocompleteAddress(input, location = null) {
        const params = {
            q: input
        }

        if (location) {
            Object.assign(params, {
                lat: location.coords.latitude,
                lon: location.coords.longitude
            })
        }

        const query = Object.keys(params)
            .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
            .join('&')

        return (
            axios
                .get(`${API_DATA_GOUV_ENDPOINT}search/?${query}`)
                .then((resolve) => resolve.data.features)
                // eslint-disable-next-line
                .catch(error => console.error(error))
        )
    }
}
