import { put, call } from 'redux-saga/effects'

import DepartmentActions from '../Redux/DepartmentRedux'
import UIActions from '../Redux/UIRedux'

import { getAgenciesBusiness } from './AgencySaga'

export function* getDepartmentsBusiness(api) {
    const response = yield call(api.getAllDepartments)
    if (response.ok) {
        yield put(DepartmentActions.getDepartmentsSuccess(response.data))
    } else {
        yield put(DepartmentActions.getDepartmentsFailure(response.error))
    }
}

export function* getDepartmentBusiness(api, { id }) {
    const response = yield call(api.getOneDepartment, { id })
    if (response.ok) {
        yield put(DepartmentActions.getDepartmentSuccess(response.data))
    } else {
        yield put(DepartmentActions.getDepartmentFailure(response.error))
    }
}

export function* getDepartments(api) {
    yield put(UIActions.showLoader())

    yield call(getDepartmentsBusiness, api)

    yield put(UIActions.hideLoader())
}

export function* getDepartment(api, { id }) {
    yield put(UIActions.showLoader())

    yield call(getDepartmentBusiness, api, { id })

    yield put(UIActions.hideLoader())
}

export function* getDepartmentAgencies(api, { payload }) {
    yield put(UIActions.showLoader())

    yield call(getDepartmentBusiness, api, { id: payload.id })
    yield call(getAgenciesBusiness, api, { payload: payload.data })

    yield put(UIActions.hideLoader())
}
