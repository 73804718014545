import React, { useState } from 'react'

import PropTypes from 'prop-types'

import i18n from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'
import X from '@nickel/ui/components/icons/X'
import CountryDropdown from '@nickel/ui/components/legacy/form/downshift/CountryDropdown'

import { getCurrentLanguageFromUrl, getSpecificLanguage } from '../../../../Utils/i18n'
import { countryAllowed, getCountryISO2, languageByCountry, mapCountryFlag } from '../../../../Utils/l10n'

import './style.scss'

const mapLinkLanguages = {
    [SupportedLocale.ES_ES]: window.REACT_APP_HOME_PATH_ENDPOINT_ES,
    [SupportedLocale.FR_FR]: window.REACT_APP_HOME_PATH_ENDPOINT_FR,
    'en-BE': window.REACT_APP_HOME_PATH_ENDPOINT_EN_BE,
    [SupportedLocale.NL_BE]: window.REACT_APP_HOME_PATH_ENDPOINT_NL_BE,
    [SupportedLocale.FR_BE]: window.REACT_APP_HOME_PATH_ENDPOINT_FR_BE,
    [SupportedLocale.PT_PT]: window.REACT_APP_HOME_PATH_ENDPOINT_PT_PT,
    [SupportedLocale.DE_DE]: window.REACT_APP_HOME_PATH_ENDPOINT_DE_DE,
    'en-FR': window.REACT_APP_HOME_PATH_ENDPOINT_EN_FR
}

export const getCountry = () => {
    return countryAllowed.map((country) => ({
        value: country,
        label: i18n.t(`common:countrySelector.${country}`)
    }))
}

const SelectLanguageModal = ({ className = 'language-switcher-modal' }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentCountry, setCurrentCountry] = useState(getCountryISO2())
    const [currentLanguage, setCurrentLanguage] = useState(getCurrentLanguageFromUrl().slice(0, 2))
    const currentLng = getCurrentLanguageFromUrl()

    const renderButtonLanguage = () => {
        return (
            <button
                type="button"
                className="language-switcher-modal-button"
                onClick={() => setIsModalOpen(!isModalOpen)}
            >
                <img
                    alt={i18n.t(`common:initial.${getSpecificLanguage(currentLng)}`)}
                    src={mapCountryFlag[getCountryISO2()]}
                />
                <span className="country-name">
                    {i18n.t(`common:languageSelectorSelected.${getSpecificLanguage(currentLng)}`)}
                </span>
            </button>
        )
    }

    const renderLanguageItems = () => {
        const _onChange = (value) => {
            setCurrentCountry(value)
            setCurrentLanguage(languageByCountry[value][0])
        }

        const _onClick = (language) => {
            setCurrentLanguage(language)
        }

        const _onValidate = () => {
            window.location = mapLinkLanguages[`${currentLanguage}-${currentCountry}`]
        }

        const _onClose = () => {
            setCurrentCountry(getCountryISO2())
            setCurrentLanguage(getCurrentLanguageFromUrl().slice(0, 2))
            setIsModalOpen(false)
        }

        return (
            <div className="language-selector-modal">
                <div className="language-selector-header">
                    <button className="language-selector-modal-close" onClick={_onClose} type="button">
                        <X />
                    </button>
                </div>
                <div className="language-selector-field">
                    <span className="language-selector-modal-label">{i18n.t(`common:countrySelectorModalLabel`)}</span>
                    <CountryDropdown
                        data-testid="selectLanguage"
                        dropdownProps={{
                            noOptionMessage: 'noOptionMessage',
                            position: 'absolute'
                        }}
                        name="selectLanguage"
                        onChange={_onChange}
                        options={getCountry()}
                        value={currentCountry}
                    />
                </div>
                <div className="language-selector-field">
                    <span className="language-selector-modal-label language-label">
                        {i18n.t(`common:languageSelectorModalLabel`)}
                    </span>
                    <div className="language-selector">
                        {languageByCountry[currentCountry].map((lang) => (
                            <button
                                className={`language-button ${lang === currentLanguage ? 'active' : ''}`}
                                key={lang}
                                onClick={() => _onClick(lang)}
                                type="button"
                            >
                                {i18n.t(`common:languageSelector.${lang}`)}
                            </button>
                        ))}
                    </div>
                </div>
                <button className="cn-button language-selector-button orange" onClick={_onValidate} type="button">
                    {i18n.t('common:validate')}
                </button>
            </div>
        )
    }

    return (
        <div className={`${className} open`}>
            {renderButtonLanguage()}
            {isModalOpen && <div className="language-selector-modal-overlay" />}
            {isModalOpen && renderLanguageItems()}
        </div>
    )
}

SelectLanguageModal.propTypes = {
    className: PropTypes.string
}

export default SelectLanguageModal
