import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getAddressesRequest: ['payload'],
    getAddressesSuccess: ['payload'],
    getAddressesFailure: ['error']
})

export const AddressTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    addresses: {
        fetching: false,
        data: null,
        error: null
    }
})

/* ------------- Selectors ------------- */

export const AddressSelectors = {
    getAddresses: (state) => state.address.addresses.data
}

/* ------------- Reducers ------------- */

const getAddressesRequest = (state = INITIAL_STATE) =>
    state.merge({ addresses: { ...state.addresses, fetching: true } })

const getAddressesSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ addresses: { ...state.addresses, data: payload } })

const getAddressesFailure = (state = INITIAL_STATE, { error }) =>
    state.merge({ addresses: { ...state.addresses, error } })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_ADDRESSES_REQUEST]: getAddressesRequest,
    [Types.GET_ADDRESSES_SUCCESS]: getAddressesSuccess,
    [Types.GET_ADDRESSES_FAILURE]: getAddressesFailure
})
