import { getCurrentLng } from '@nickel/i18n'
import createL from '@nickel/l10n/createL'

import logoPT from '../../assets/logo-pt.svg'
import logo from '../../assets/logo.svg'

export const getNickelLogo = () =>
    createL(getCurrentLng().toUpperCase())({
        'PT-PT': logoPT,
        default: logo
    })

export const getNickelPath = () =>
    createL(getCurrentLng().toUpperCase())({
        'ES-ES': window.REACT_APP_NICKEL_PATH_ES,
        'FR-FR': window.REACT_APP_NICKEL_PATH_FR,
        'FR-BE': window.REACT_APP_NICKEL_PATH_FR_BE,
        'NL-BE': window.REACT_APP_NICKEL_PATH_NL_BE,
        'EN-US': window.REACT_APP_NICKEL_PATH_EN_BE,
        'PT-PT': window.REACT_APP_NICKEL_PATH_PT_PT,
        'DE-DE': window.REACT_APP_NICKEL_PATH_DE_DE,
        'EN-GB': window.REACT_APP_NICKEL_PATH_EN_FR,
        default: window.REACT_APP_NICKEL_PATH_FR
    })

export const getRedirectPath = () =>
    createL(getCurrentLng().toUpperCase())({
        'ES-ES': ['/es', '/es-es'],
        'FR-FR': ['/fr', '/fr-fr'],
        'FR-BE': ['/fr-be'],
        'NL-BE': ['/nl-be'],
        'EN-US': ['/en-be'],
        'PT-PT': ['/pt-pt'],
        'DE-DE': ['/de-de'],
        'EN-GB': ['/en-fr'],
        default: ['/fr-fr']
    })

export const getHomePath = () =>
    createL(getCurrentLng().toUpperCase())({
        'ES-ES': window.REACT_APP_HOME_PATH_ENDPOINT_ES,
        'FR-FR': window.REACT_APP_HOME_PATH_ENDPOINT_FR,
        'FR-BE': window.REACT_APP_HOME_PATH_ENDPOINT_FR_BE,
        'NL-BE': window.REACT_APP_HOME_PATH_ENDPOINT_NL_BE,
        'EN-US': window.REACT_APP_HOME_PATH_ENDPOINT_EN_BE,
        'PT-PT': window.REACT_APP_HOME_PATH_ENDPOINT_PT_PT,
        'DE-DE': window.REACT_APP_HOME_PATH_ENDPOINT_DE_DE,
        'EN-GB': window.REACT_APP_HOME_PATH_ENDPOINT_EN_FR,
        default: window.REACT_APP_HOME_PATH_ENDPOINT_FR
    })

export const getRegistrationPath = () => {
    const nclid = sessionStorage.nclid ? `?nclid=${sessionStorage.nclid}` : ''

    return `${window.REACT_APP_REGISTRATION_PATH}${nclid}`
}

export const getDepartmentListPath = () =>
    createL(getCurrentLng().toUpperCase())({
        'ES-ES': window.REACT_APP_DEPARTMENT_LIST_PATH_ES,
        'FR-FR': window.REACT_APP_DEPARTMENT_LIST_PATH_FR,
        'FR-BE': window.REACT_APP_DEPARTMENT_LIST_PATH_FR_BE,
        'NL-BE': window.REACT_APP_DEPARTMENT_LIST_PATH_NL_BE,
        'EN-US': window.REACT_APP_DEPARTMENT_LIST_PATH_EN_BE,
        'PT-PT': window.REACT_APP_DEPARTMENT_LIST_PATH_PT_PT,
        'DE-DE': window.REACT_APP_DEPARTMENT_LIST_PATH_DE_DE,
        'EN-GB': window.REACT_APP_DEPARTMENT_LIST_PATH_EN_FR,
        default: window.REACT_APP_DEPARTMENT_LIST_PATH_FR
    })

export const hasDepartmentList = () => !!getDepartmentListPath()
