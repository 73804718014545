/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react'

import slice from 'lodash/slice'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import iconFacebook from '../../../../../assets/facebook.svg'
import iconInstagram from '../../../../../assets/instagram.svg'
import iconTwitter from '../../../../../assets/twitter.svg'
import iconYoutube from '../../../../../assets/youtube.svg'
import { NavSelectors } from '../../../../Redux/NavRedux'
import { getNickelLogo } from '../../../../Utils/paths'
import SelectLanguageModal from '../SelectLanguageModal'

import './style.scss'

const appStoreImageMap = {
    appstore: 'https://nickel.eu/themes/custom/compte_nickel_theme/images/App_Store_Badge.svg',
    googleplay: 'https://nickel.eu/themes/custom/compte_nickel_theme/images/Google-Play-Badgeb.png'
}

const mapSocialIcon = {
    facebook: iconFacebook,
    instagram: iconInstagram,
    twitter: iconTwitter,
    youtube: iconYoutube
}

class Footer extends PureComponent {
    numberItemsPerColumn = (links) => Math.round(links.length / 2)

    getCategories = () => {
        const { footer = [] } = this.props
        const col1 = slice(footer, 0, 1)
        const col2 = slice(footer, 1, 2)
        return [col1, col2]
    }

    renderItems = (index) =>
        this.getCategories()[index].map((item) => {
            const hasChildren = Boolean(item.children)
            return (
                <li key={`category-${item.label}`}>
                    <a href={hasChildren ? '#' : item.link} className={`link ${hasChildren ? 'category' : ''}`}>
                        {item.label}
                    </a>
                    {hasChildren && (
                        <ul>
                            {item.children.map((subItem) => (
                                <li key={`subItem-${subItem.label}`}>
                                    <a href={subItem.link} className="link">
                                        {subItem.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            )
        })

    renderItemsColThree = () => {
        const { store } = this.props

        if (!store) return null
        return store.map((item) => (
            <li className="item" key={item.label}>
                <a href={item.link} className="link">
                    <img src={appStoreImageMap[item.label]} alt={item.label} width="135" height="40" className="img" />
                </a>
            </li>
        ))
    }

    renderItemsColFour = () => {
        const { social } = this.props

        if (!social) return
        return social.map((item) => (
            <li className="item" key={item.label}>
                <a href={item.link} className="link social">
                    <img src={mapSocialIcon[item.label.toLowerCase()]} alt={item.label} />
                </a>
            </li>
        ))
    }

    renderItemsBecomeDistributor = () => {
        const { footerButton } = this.props

        if (!footerButton) return
        return (
            <a href={footerButton.link} title={footerButton.title} className="become-distributor-link">
                {footerButton.title}
            </a>
        )
    }

    renderItemFooterText = () => {
        const { footerText } = this.props

        if (!footerText) return
        return (
            <>
                <img src={getNickelLogo()} alt="Nickel logo" />
                <span>
                    <strong>{footerText.title}</strong>
                    &nbsp;
                    {footerText.text}
                </span>
            </>
        )
    }

    render() {
        return (
            <footer className="footer">
                <div className="footer-inner">
                    <div className="footer-block footer-wrapper">
                        <div className="footer-block footer-block-text">{this.renderItemFooterText()}</div>
                        <div className="footer-block footer-wrapper-social">
                            <div className="footer-block footer-wrapper-link">
                                <div className="footer-block footer-block-link">
                                    <ul>{this.renderItems(0)}</ul>
                                </div>
                                <div className="footer-block footer-block-link">
                                    <ul>{this.renderItems(1)}</ul>
                                </div>
                            </div>
                            <div className="footer-block footer-block-language">
                                {this.renderItemsBecomeDistributor()}
                                <SelectLanguageModal />
                            </div>
                        </div>
                    </div>
                    <div className="footer-block footer-block-social">
                        <div className="footer-block-app">
                            <ul>{this.renderItemsColThree()}</ul>
                        </div>
                        <div className="footer-block-social-network">
                            <ul>{this.renderItemsColFour()}</ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

Footer.propTypes = {
    footer: PropTypes.arrayOf(PropTypes.shape()),
    social: PropTypes.arrayOf(PropTypes.shape()),
    store: PropTypes.arrayOf(PropTypes.shape())
}

const mapStateToProps = createStructuredSelector({
    footer: NavSelectors.getNavLinksFooter,
    social: NavSelectors.getNavLinksSocial,
    store: NavSelectors.getNavLinksStore,
    footerButton: NavSelectors.getNavLinkFooterButton,
    footerText: NavSelectors.getNavFooterText
})

export default connect(mapStateToProps)(Footer)
