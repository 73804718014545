import i18n from '../I18n'

import { titleCase } from './Formatted'

const domZipCodes = ['971', '973', '975', '972', '974', '976', '984', '987', '986', '988']

export const isZipCodeFromDomTom = (zipCode) => domZipCodes.some((code) => zipCode.startsWith(code))

export const hasCivility = (string) => /Monsieur|Madame/gi.test(string)

export const getAgencyNameAndType = (agency) => {
    if (!agency || !agency.address || !agency.address.zipCode || !agency.name) return {}
    const isDomTom = isZipCodeFromDomTom(agency.address.zipCode)
    const mustBeSanitized = hasCivility(agency.name)
    const agencyType = isDomTom ? i18n.t('title:agency') : i18n.t('title:tobacco-shop')
    if (mustBeSanitized) {
        return { agencyType, agencyName: titleCase(agencyType) }
    }
    return { agencyType, agencyName: titleCase(agency.name) }
}
