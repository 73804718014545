import startsWith from 'lodash/fp/startsWith'

const FLAG_PREFIX = 'REACT_APP_FLAG_'

const isEnvFlag = startsWith(FLAG_PREFIX)

const flags = Object.entries(window).filter(([key]) => isEnvFlag(key))

const activeFlags = flags.filter(([, val]) => val === 'true').map(([key]) => key.replace(FLAG_PREFIX, ''))

export const isActiveFlag = (flagKey) => activeFlags.includes(flagKey)
