import { getGlobalISO2 } from '@nickel/l10n/config'

import { handleErrorResponse, handleSuccessResponse } from '../../Utils/HandlerResponseRequest'
import * as WSSwagger from '../ws-swagger/ws_swagger'

const getAllAgencies = (data) =>
    new Promise((resolve) => {
        WSSwagger.getAllStoresUsingGET({ ...data, country: getGlobalISO2() })
            .then((response) => {
                resolve(handleSuccessResponse(response))
            })
            .catch((error) => {
                resolve(handleErrorResponse(error))
            })
    })

const getStoreCount = ({ country }) =>
    new Promise((resolve) => {
        WSSwagger.getStoreCountUsingGET({ country })
            .then((response) => {
                resolve(handleSuccessResponse(response))
            })
            .catch((error) => {
                resolve(handleErrorResponse(error))
            })
    })

const getAgency = (id) =>
    new Promise((resolve) => {
        WSSwagger.getStoreUsingGET(id)
            .then((response) => {
                resolve(handleSuccessResponse(response))
            })
            .catch((error) => {
                resolve(handleErrorResponse(error))
            })
    })

export default {
    getAgency,
    getAllAgencies,
    getStoreCount
}
