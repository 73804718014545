import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { withNamespaces } from 'react-i18next'
import { connect } from 'react-redux'

import './Styles/layout.scss'

import { URL_NOT_FOUND } from '../Config/Constants'
import { AgencySelectors } from '../Redux/AgencyRedux'
import DepartmentActions, { DepartmentSelectors } from '../Redux/DepartmentRedux'
import { splitUrl, titleCase } from '../Utils/Formatted'
import { hasDepartmentList } from '../Utils/paths'

import AppLayout from './Components/Commons/AppLayout'
import Breadcrumbs from './Components/Commons/Breadcrumbs'
import Department from './Components/Department'

class DepartmentScreen extends Component {
    componentDidMount() {
        const { match } = this.props
        const params = splitUrl(match.url)
        this.props.getDepartmentAgencies(params[3], { department: params[3] })
    }

    componentDidUpdate() {
        const { agencies, department } = this.props
        if (agencies && agencies.length === 0 && department === null) this.props.history.push(URL_NOT_FOUND)
        if (!hasDepartmentList()) this.props.history.push(URL_NOT_FOUND)
    }

    renderHelmet = (department) => {
        const { t } = this.props

        return (
            <Helmet>
                <meta name="title" content={t('helmet:department.title', { department: titleCase(department.name) })} />
                <meta
                    name="description"
                    content={t('helmet:department.description', { department: titleCase(department.name) })}
                />
                <title>{t('helmet:department.title', { department: titleCase(department.name) })}</title>
            </Helmet>
        )
    }

    renderBreadcrumbs = (department) => {
        if (department) {
            return <Breadcrumbs data={[department.name]} />
        }

        return null
    }

    render() {
        const { match, agencies, department } = this.props
        if (!department) return <AppLayout />

        return (
            <AppLayout>
                {this.renderHelmet(department)}

                <div
                    className="mdc-layout-grid max-width-1200"
                    itemProp="provider"
                    itemScope
                    itemType="http://schema.org/FinancialService"
                >
                    <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                            {this.renderBreadcrumbs(department)}
                        </div>
                    </div>

                    <Department match={match} department={department} agencies={agencies} />
                </div>
            </AppLayout>
        )
    }
}

DepartmentScreen.propTypes = {
    match: PropTypes.shape(),
    history: PropTypes.shape(),
    t: PropTypes.func,
    department: PropTypes.shape(),
    agencies: PropTypes.arrayOf(PropTypes.object),
    getDepartmentAgencies: PropTypes.func
}

const mapStateToProps = (state) => ({
    department: DepartmentSelectors.getDepartment(state),
    agencies: AgencySelectors.getAgencies(state)
})

const mapDispatchToProps = (dispatch) => ({
    getDepartmentAgencies: (id, data) => dispatch(DepartmentActions.getDepartmentAgencies({ id, data }))
})

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces()(DepartmentScreen))
