import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getClustersRequest: ['payload'],
    getClustersSuccess: ['payload'],
    getClustersFailure: ['error']
})

export const ClusterTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    clusters: {
        fetching: false,
        data: null,
        error: null
    }
})

/* ------------- Selectors ------------- */

export const ClusterSelectors = {
    getClusters: (state) => state.cluster.clusters.data
}

/* ------------- Reducers ------------- */

const getClustersRequest = (state = INITIAL_STATE) => state.merge({ clusters: { ...state.clusters, fetching: true } })

const getClustersSuccess = (state = INITIAL_STATE, { payload }) =>
    state.merge({ clusters: { ...state.clusters, data: payload } })

const getClustersFailure = (state = INITIAL_STATE, { error }) => state.merge({ clusters: { ...state.clusters, error } })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_CLUSTERS_REQUEST]: getClustersRequest,
    [Types.GET_CLUSTERS_SUCCESS]: getClustersSuccess,
    [Types.GET_CLUSTERS_FAILURE]: getClustersFailure
})
