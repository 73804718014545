import React from 'react'

import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'

import Agencies from '../../Components/List/agencies'
import Section from '../../Components/Section'
import Separator from '../../Components/Separator'
import H1 from '../../Components/Title/H1'

import AccountForAll from './Commons/AccountForAll'

const Department = ({ t, department, agencies = [], match }) => (
    <>
        <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
                <Section>
                    <H1>
                        {department.name}
                        &nbsp;:&nbsp;
                        {t('title:listAgencies')}
                    </H1>
                </Section>
            </div>
        </div>
        <Agencies match={match} agencies={agencies} />
        <Separator />

        <AccountForAll />
    </>
)

Department.propTypes = {
    t: PropTypes.func,
    match: PropTypes.shape(),
    department: PropTypes.shape(),
    agencies: PropTypes.arrayOf(PropTypes.object)
}

export default withNamespaces('department')(Department)
