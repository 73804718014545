import React, { PureComponent } from 'react'

import L from 'leaflet'
import PropTypes from 'prop-types'
import { withNamespaces } from 'react-i18next'
import { Map, TileLayer, ZoomControl } from 'react-leaflet'

import constants from '../../Config/Constants'

import 'leaflet/dist/leaflet.css'
import './store-locator.scss'

class MapNickelStoreLocator extends PureComponent {
    componentDidMount = () => {
        // It's to get the ref of component in the parent's component
        this.props.onRef(this)
    }

    /**
     * @description Handler to update the current Map's bounds
     */
    handleOnChangeCurrentBounds = () => {
        const { handleOnChangeCurrentBounds } = this.props
        // eslint-disable-next-line
        const currentBounds = this.refs.leafletMapRef.leafletElement.getBounds()
        handleOnChangeCurrentBounds(currentBounds)
    }

    _setGeolocation = () => {
        // eslint-disable-next-line
        this.refs.leafletMapRef.leafletElement.locate({
            setView: true,
            maxZoom: 15
        })
    }

    render() {
        const { bounds, t, children } = this.props
        const northEast = L.latLng(bounds._northEast.lat, bounds._northEast.lng)
        const southWest = L.latLng(bounds._southWest.lat, bounds._southWest.lng)
        return (
            <Map
                bounds={L.latLngBounds(northEast, southWest)}
                maxBounds={L.latLngBounds(constants.MAX_BOUNDS.northEast, constants.MAX_BOUNDS.southWest)}
                zoomSnap={constants.ZOOM_SNAP}
                zoomDelta={constants.ZOOM_DELTA}
                attributionControl={false}
                zoomControl={false}
                dragging
                minZoom={constants.MIN_ZOOM}
                className="store-locator-map"
                onZoomStart={this.stopScrollPage}
                onZoomEnd={this.handleOnChangeCurrentBounds}
                onDragEnd={this.handleOnChangeCurrentBounds}
                // eslint-disable-next-line
                ref="leafletMapRef"
            >
                <TileLayer attribution="" url={constants.URL_GOOGLE_MAP} />
                <ZoomControl position="bottomright" zoomInTitle={t('zoomIn')} zoomOutTitle={t('zoomOut')} />
                <button type="button" className="cn-button orange marker" onClick={this._setGeolocation}>
                    <i className="icon icon-cursor" />
                </button>
                {children}
            </Map>
        )
    }
}

MapNickelStoreLocator.propTypes = {
    bounds: PropTypes.shape().isRequired,
    handleOnChangeCurrentBounds: PropTypes.func,
    children: PropTypes.node,
    t: PropTypes.func.isRequired,
    onRef: PropTypes.func.isRequired
}

export default withNamespaces(['common'])(MapNickelStoreLocator)
